import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "../../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { copyStyles } from '@angular/animations/browser/src/util';
import {Router} from '@angular/router';



@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  titleModal = '';
  form: FormGroup;
  submitted = false;
  loading: boolean = false;
  products = [];
  branchs = [];
  branchType = [];
  deliveryFE = [];
  operationNature = [];
  operationType = [];
  salesType = [];
  shippingFE = [];
  formatCAFE = [];
  typeDocuments = [];
  typePayments = [];
  payType = [];
  totalEnd = 0;
  selectedClient = { phone: '', email: ''};
  clients = [];
  selectedproducts = [];
  productPlus:any;
  userId:'';
  totals = {
    itbms:0,
    neto:0,
    items:0,
    total:0,
    received:0,
    turned:0
  }

  constructor(
    private httpServices: HttpService, 
    public toastr: ToastrService, 
    private router:Router,
    private fb: FormBuilder) { 

      this.createForm();

    }

  get f(): any { return this.form.controls; }


 
  ngOnInit() {
    this.loadData();
  }

  private createForm(): void {
      this.form = this.fb.group(
        {
      
          document_type: [ 1,  [Validators.required]  ],
          branch_office: [ 1,  [Validators.required]  ],
          billing_point: [ '001', [Validators.required],   ],
          operation_nature: [ 1,  [Validators.required]  ],
          operation_type: [ 1,  [Validators.required]  ],
          shipping_FE: [ '1',  [Validators.required]  ],
          delivery_CAFE: [ 1,  [Validators.required]  ],
          format_CAFE: [ 1,  [Validators.required]  ],
          sale_type: [ 1,  [Validators.required]  ],
          user_id: [ '',  [Validators.required]  ],
         // status_id: [ 1,  [Validators.required]  ],
          info_FE: [ '',  [Validators.required]  ],
          type_pay_id: [ '',  [Validators.required]  ],
          term_payment_id: [ '',  [Validators.required]  ],
          amount: [ 0,  [Validators.required]  ],
          products: [],
          totals: {},
        }
      );
  }


  loadData() {
    this.httpServices.getHttpAuth("/extrainvoice")
    .subscribe( x => {
      console.log('datos',x);
      this.branchType = x.branch_type;
      this.deliveryFE = x.delivery_FE;
      this.operationNature = x.operation_nature;
      this.operationType = x.operation_type;
      this.salesType = x.sales_type;
      this.shippingFE = x.shipping_FE;
      this.typeDocuments = x.type_documents;
      this.typePayments = x.type_payments;
      this.payType = x.pay_type;
      this.formatCAFE = x.format_CAFE;
    });
    this.httpServices.getHttpAuth("/products?paginate=1")
    .subscribe( x => {
      this.products = x.data;
      //console.log(x.data);
    });
    this.httpServices.getHttpAuth("/extrainvoice-index?user=true")
    .subscribe( x => {
      //console.log('datos extra',x);
      this.clients = x.users;
      this.branchs = x.branchs;
    });

    
  }

  onSubmit(): void {
    this.submitted = true;
    this.form.value.user_id = this.userId;
    //console.log('Lo que se envia',this.form.value);
    if(this.selectedproducts.length!==0) {

      this.form.value.products = this.selectedproducts;
      this.form.value.totals = this.totals;
      

      if ( this.form.valid ) {
        this.loading = true;
        this.httpServices.postHttpAuth('/documents', this.form.value)
        .subscribe( x => {
          //console.log(x);
          this.toastr.success('Factura registrada con exito');
          this.router.navigate(['/issuance-documents']);
          this.loading = false;
        }, err => {
          this.loading = false;
        });
      
      } else {
        this.toastr.error('Faltan datos por llenar');
      }


    } else {
      this.toastr.error('No tiene productos cargados');
    }

    
  }

  onChangeClient(event){ 
    this.userId = event.value.id;
    this.selectedClient.phone= event.value.phone;
    this.selectedClient.email= event.value.email;
  }

  calcTotal(index) {
    let precio = parseFloat(this.selectedproducts[index].price);
    let cantidad = this.selectedproducts[index].amount;
    let descuento = 0;
    if(this.selectedproducts[index].discount!=0) {
      descuento = (precio * this.selectedproducts[index].discount)/100;
    }
    let impuesto = 0;
    if(this.selectedproducts[index].itbms!=0) {
      impuesto = (precio * this.selectedproducts[index].itbms)/100;
    }

    this.selectedproducts[index].item_price = precio - descuento;
    this.selectedproducts[index].value_itbms = impuesto;
    this.selectedproducts[index].total = (impuesto - descuento + precio )  * cantidad;

    this.sumarTotales();
     

  }

  addItem() {
    if(this.productPlus !== {} && this.productPlus !== undefined){
      let precio = parseFloat(this.productPlus.price);
      let descuento = 0;
      let impuesto = 0;
      if (this.productPlus.itbms != 0) {
        impuesto = (precio * this.productPlus.itbms)/100;
      }
      let total = (impuesto - descuento + precio ) * 1;
     
      this.selectedproducts.push({
        code: this.productPlus.code,
        amount:1, 
        discount:0,
        description: this.productPlus.description,
        price: this.productPlus.price,
        item_price: this.productPlus.price,
        itbms: this.productPlus.itbms,
        value_itbms: impuesto,
        total: total,
      });

      var indice = this.products.indexOf(this.productPlus);
      this.products.splice(indice, 1);
      this.sumarTotales();

    }

  }

  sumarTotales () {
    let select = this.selectedproducts;
    /*this.selectedproducts.forEach(function(value, key) {
      t = t + value.total;
      i = i + value.value_itbms;
      p = p + parseFloat(value.item_price);
      n = n + (parseFloat(value.price) * value.amount);
    });*/
      
    this.totals.total = select.reduce((sum, item) => sum + item.total, 0);
    this.totals.items = select.reduce((sum, item) => sum + parseFloat(item.item_price), 0);
    this.totals.neto = select.reduce((sum, item) => sum + (parseFloat(item.price) * item.amount), 0);
    this.totals.itbms = select.reduce((sum, item) => sum + item.value_itbms, 0);

    this.totalEnd = this.totals.total;

  }

  deleteItem(data,index) {
    this.products.push(data);
    this.selectedproducts.splice(index, 1);
    this.sumarTotales();
  }


}
