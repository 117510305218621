import { Component, OnInit } from '@angular/core';
import { HttpService } from './../../services/Http/http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-invoices',
	templateUrl: './aditional.component.html',
	styleUrls: ['./aditional.component.scss']
})
export class AditionalComponent implements OnInit {
	constructor(
		public httpServices: HttpService,
		public toastr: ToastrService
	) {}
	
	data: any = [];
	modalData: any = {
		id: 0,
		date: '',
		client: '',
		cellphone: '',
		request: '',
		status: '',
		type: '',
		medical_order: null,
	}

	ngOnInit() {
		this.httpServices.getHttpAuth('/aditional').subscribe(x => {
			this.data = x.aditonals.map(solicitud => {
				if (solicitud.medical_order_image == null) {
					return {
						...solicitud
					};
				} else {
					let recipe: any = {
						value: solicitud.medical_order_image
					};

					if (this.isPdf(solicitud.medical_order_image)) {
						recipe.label = 'pdf';
					} else recipe.label = 'img';

					return {
						...solicitud,
						medical_order_image: recipe
					};
				}
			});

			console.log(this.data);
		});
	}

	public isPdf(url: string): boolean {
		const isPdf = /pdf$/.test(url);
		console.log(isPdf);

		return isPdf;
	}

	openDetailModal(item){
		this.modalData.id = item.id;
		this.modalData.date = item.created_at;
		this.modalData.client = item.user.name+' '+item.user.lastname+' '+item.user.email;
		this.modalData.cellphone = item.user.phone;
		this.modalData.request = item.message;
		this.modalData.status = this.getStatus(item.status);
		this.modalData.type = (item.type_request) ? item.type_request.name : 'No posee tipo de solicitud';
		this.modalData.medical_order = item.medical_order_image;
		(<any>$('#detail')).modal('show');
	}

	changeStatus(id, data) {
		this.httpServices
			.putHttpAuth('/aditional/' + id, {
				status: data
			})
			.subscribe(x => {
				this.toastr.success(
					'Estado actualizado de manera correcta',
					'Proceso exitoso',
					{
						closeButton: false
					}
				);
			});
	}

	getStatus(num){
		if(num == null || num == 0) return 'Rechazado';
		if(num == 1) return 'Pendiente';
		if(num == 2) return 'Procesando';
		if(num == 3) return 'Solicitud procesada';
	}
}
