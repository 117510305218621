import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtertotal'
})
export class FilterTotalIssuance implements PipeTransform {

  transform(array: any): any {

    let t=0;

    t = array.reduce((sum, item) => sum + parseFloat(item.amount), 0)

    return t.toFixed(2);

  }

}
