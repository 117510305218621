import { Component, OnInit } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  
  htmlContent: String ="";
  constructor(public toastr: ToastrService,private httpServices: HttpService) { }

  ngOnInit() {
    this.httpServices.getHttpAuth("/terms")
    .subscribe(x => {
      this.htmlContent = x.terms.content;
    });
  }
  
  update(){
    this.httpServices.putHttpAuth("/terms/1",{content:this.htmlContent})
    .subscribe(x => {
      // this.htmlContent = x.terms.content;
      this.toastr.success("Terminos y condiciones modificados de manera correcta",'Info!',{
        closeButton: false  
      });
    });
  }

}
