import { Component, OnInit,ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "./../../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute} from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  constructor(private router:Router,private _route: ActivatedRoute, private httpServices: HttpService, public toastr: ToastrService) { }
 
  plans: any = [];
  plansType: any = [];
  body : any;
  public loading: boolean=false;
  isEditing: boolean = false;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Listado de planes', 
        exportOptions:{
          stripNewlines: false,
          format: {
              body: function(data, row, column, node) {              
                     if(column==5)
                       return data.replace('$', '');
                     else if(column==8)
                       return data.replace(/<\/?[^>]+(>|$)/g, "");
                     else 
                      return data;
              }
          },
          columns: ':not(.notexport)'
        }
      }
    ]
  };
  dtTrigger: Subject<any> = new Subject();
  
  ngOnInit() {
      this.body = {
        name : "",
        price : "",
        plan_type_id : ""
      };
      this.resetData();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  openModal(type,data?){
      
      if(type=='new'){
          this.body = {
            name : "",
            price : "",
            plan_type_id : ""
          };
          this.isEditing = false;
      }else{ 
          this.body = {
            id:data.id,
            name : data.name,
            price : data.price,
            plan_type_id : data.plan_type_id
          };
          this.isEditing = true;
      }
      
      (<any>$('#edit')).modal('show');
  }
  
  crud(type,id?){
      
      this.loading=true;
      
      if(type=='edit')
          var request = this.httpServices.putHttpAuth("/plan/"+this.body.id,this.body);
      else if(type=='delete')
            var request = this.httpServices.deleteHttpAuth("/plan/"+id,this.body);    
      else
           var request = this.httpServices.postHttpAuth("/plan",this.body);
      
      
      request.subscribe(
          x => {
          (<any>$('#edit')).modal('hide');
          this.loading=false;
          this.toastr.success("Se ha "+ (
              type=='edit' ? 'editado' : type=='delete' ? "eliminado" : "creado") +" el plan de manera correcta",'Proceso exitoso',{
            closeButton: false  
          });
          
          this.resetData();
        },
          err => this.loading=false);
  }
  
  resetData(){
      this.loading=true
      this.httpServices.getHttpAuth("/plan")
      .subscribe(x => {
          this.loading=false
          this.plans =  x.plans;
          this.plansType = x.types;
          if(this.plansType != []) this.plansType[1].name = "Plan de Automóviles";
          this.rerender();
      },x=>this.loading=false);
  }

}
