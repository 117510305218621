import { Component, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

            
@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit {

  constructor(public httpServices: HttpService, public toastr: ToastrService) { }
  data: any = []
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { 
          extend: 'excel', 
          title: 'Reporte de citas medicas', 
          exportOptions:{ 
            stripNewlines: false,
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
          }
        }
    ] 
  };
  dtTrigger: Subject<any> = new Subject();;
  modalData: any = {
    id: 0,
    date: '',
    client: '',
    speciality: '',
    doctor: '',
    reason: '',
    address: '',
    medical_center: '',
    doctor_number: '',
    status: '',
  }
  
  ngOnInit() {
    this.httpServices.getHttpAuth("/getallappointments")
    .subscribe( x => {
      console.log(x);
      this.rerender();
      this.data = x.appointments;
    });
  }  
  
  changeStatus(id, data){
    
    this.httpServices.putHttpAuth("/medicalappoinments/"+id,{
      status:data
    }).subscribe(x => {
      this.toastr.success("Estado actualizado de manera correcta",'Proceso exitoso',{
        closeButton: false  
      });
    })
  }
  
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  openDetailModal(item){
    this.modalData.id = item.id;
    this.modalData.date = item.created_at;
    this.modalData.client = item.user.name+' '+item.user.lastname+' '+item.user.email;
    this.modalData.speciality = item.speciality_name;
    this.modalData.doctor = item.doctor_name;
    this.modalData.reason = item.appoinment_reason;
    this.modalData.address = item.doctor_address;
    this.modalData.medical_center = (item.address_doctor ? item.address_doctor : "");
    this.modalData.doctor_number = (item.num_doctor ? item.num_doctor : "");
    this.modalData.status = this.getStatus(item.status);
    (<any>$('#detail')).modal('show');
  }

  getStatus(num){
    if(num == null || num == 0) return 'Rechazado';
    if(num == 1) return 'Pendiente';
    if(num == 2) return 'Procesando';
    if(num == 3) return 'Solicitud procesada';
  }

}
