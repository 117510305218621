import { 
         Component,
         OnInit,
         ViewChild,
         AfterViewInit,
         ElementRef
} from '@angular/core';
import { HttpService } from "./../../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatStepper } from '@angular/material';
import * as firebase from 'firebase';
import * as moment from 'moment';
import {Router, ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  
  @ViewChild('stepper') stepper: ElementRef<any>;
  constructor(private router:Router,private _route: ActivatedRoute, private httpServices: HttpService, public toastr: ToastrService) { }
  hasPolicy: boolean = true;
  isEditing: boolean = false;
  idSelected: number = 0;
  stepperTest: number = 0;
  role: string = "1";
  roleAuth: number = 1;
  name: string = "";
  lastname: string = "";
  fuid: string = "";
  user: any = {};
  app : any = null;
  email: string = "";
  password: string = "";
  document: string = null;
  frequencies: any = [];
  firebaseuid: string = "";
  dependents: any = [];
  countries: any = [];
  insurance: any = [];
  editingDependent: boolean = false;
  policyUrl: String ="";
  policyCarnetUrl: String ="";
  medicalRedUrl: String ="";
  test: string = "";
  provinces: any = [];
  districts: any = [];
  corrections: any = [];
  countries_forigens: any = [];  
  birthDateCorrection = "";
  birth_date_change = false;

  dependent: any = {
    name:"",
    lastname:"",
    relationship_id:"",
    birth_date: ""
  };
  step1: any = {
    step:1,
    name:"",
    lastname:"",
    document_user:"",
    gender:"m",
    birth_date:"",
    document: {},
    dependents:[],
    fuid:"",
    client_type_id: 1,
    client_type_children_id: null,
    // Contributynte y Gobierno
    identification_number: null,
    check_digit: null,
    business_name: null,
    foreign_identification: null,
    foreign_country_id: null,
    // Todos
    province_id: null,
    district_id: null,
    correction_id: null,
    address: null,
  };
  step2: any = {
    step:2,
    frequency:null,
    doctors_visit: null,
    has_policy: true,
    fuid:""
  };
  step3: any = {
    "step":3,
  	"policy_number":"",
  	"date_of_issue":"",
  	"country":"",
  	"value":"",
  	"insurance_carrier":"",
  	"policy_carnet":{},
  	"medical_red":{},
    fuid:"",
    deductible:0
  };

  public uploadedFiles: Array<File> = [];
  public uploadedFilesCarrier: Array<File> = [];
  public uploadedFilesCarrierCarnet: Array<File> = [];
  public uploadedFilesRed: Array<File> = [];
  public loading = false;

    ngOnInit() {
      this.roleAuth = parseInt(localStorage.getItem('role'));
      (<any>this.stepper)._selectedIndex = 0;
      
      this.httpServices.getHttpAuth('/relations')
      .subscribe(x => {
        this.dependents=x.relations;
      });
      
      this.httpServices.getHttpAuth("/countries")
      .subscribe(x => {
        this.countries = x.countries;
      });

      this.httpServices.getHttpAuth("/countries_forigens")
      .subscribe(x => {
        this.countries_forigens = x;
      });

      this.httpServices.getHttpAuth("/provinces")
      .subscribe(x => {
        this.provinces = x;
      });
      
      this.httpServices.getHttpAuth("/insurance")
      .subscribe(x => {
        this.insurance = x.insurance;
      });
      
      this.step1  = {
        step:1,
        name:"",
        lastname:"",
        document_user:"",
        gender:"m",
        birth_date:"",
        document: {},
        dependents:[],
        fuid:"",
        client_type_id: 1,
        client_type_children_id: null,
        // Contributynte y Gobierno
        identification_number: null,
        check_digit: null,
        business_name: null,
        foreign_identification: null,
        foreign_country_id: null,
        // Todos
        province_id: null,
        district_id: null,
        correction_id: null,
        address: null,
      };
      this.step2  = {
        step:2,
        frequency:null,
        doctors_visit: null,
        has_policy: true,
        fuid: "",
        provincia: 1,
        distrito: 1,
        corregimiento: 1,
      };
      this.step3  = {
        "step":3,
        "policy_number":"",
        "date_of_issue":"",
        "country":"",
        "value":"",
        "insurance_carrier":"",
        "policy_files":{},
        "policy_carnet":{},
        "medical_red":{},
        fuid:""
      }

      this.httpServices.getHttpAuth("/frequency")
      .subscribe(x => {
        this.frequencies=x.frenquencies;
      });
      if (!firebase.apps.length) {
        this.app = firebase.initializeApp({
            apiKey: "AIzaSyD8fQVXEWe30RhfFZgaGW2ts24ua16l-gA",
            authDomain: "localhost",
            databaseURL: "https://newagent-oatenu.firebaseio.com",
            projectId: "newagent-oatenu",
            storageBucket: "newagent-oatenu.appspot.com",
            //messagingSenderId: "737315672349"
          });
      }
    ;
    
      
      if(this._route.snapshot.params.id){
        this.idSelected = this._route.snapshot.params.id;
        this.loading=true;
        this.httpServices.getHttpAuth("/user/"+this.idSelected)
        .subscribe(x=>{
          this.loading=false;
          this.user=x.user;
          if(x.user.status==0) {
            this.firebaseuid= x.user.firebase_uid;
            for (let i = 0; i < x.user.step_register; i++) {
              (<any>this.stepper).selectedIndex = i;
              (<any>this.stepper).selected.completed = true;
              (<any>this.stepper).selected.editable = false;
            }
            (<any>this.stepper).selectedIndex = x.user.step_register;
          }
          else {

            if (x.user.client) {
              this.step1.client_type_id = x.user.client.client_type_id;
              this.step1.client_type_children_id = x.user.client.client_type_child_id;
              this.step1.identification_number = x.user.client.identification_number;
              this.step1.check_digit = x.user.client.check_digit;
              this.step1.business_name = x.user.client.business_name;
              this.step1.foreign_identification = x.user.client.foreign_identification;
              this.step1.foreign_country_id = x.user.client.foreign_country_id;
              this.step1.address = x.user.client.address;
              this.step1.correction_id = x.user.client.correction_id;

              if (x.user.client.correction) {
                this.step1.province_id = x.user.client.correction.district.province_id;
                this.step1.district_id = x.user.client.correction.district_id;

                // Districts
                this.httpServices.getHttpAuth("/provinces/" + x.user.client.correction.district.province_id)
                  .subscribe(response => {
                    this.districts = response;
                  });
                // Corregimientos
                this.httpServices.getHttpAuth("/districts/" + x.user.client.correction.district_id)
                  .subscribe(response => {
                    this.corrections = response;
                  });
              }

            }

            this.isEditing = true;
            (<any>this.stepper).selected.completed = false;
            (<any>this.stepper).selected.editable = false;
            (<any>this.stepper).selectedIndex = 1;
            this.step1.name = x.user.name;
            this.step1.lastname = x.user.lastname;
            this.step1.document_user = x.user.document;
            this.step1.gender = x.user.gender;
            this.birthDateCorrection = x.user.birth_date;
            this.step1.birth_date = moment(moment(x.user.birth_date).add(24, 'hours').calendar()).format('YYYY-MM-DD');
            this.step1.phone = x.user.phone;
            this.step1.dependents=x.user.dependents;
            for (let i = 0; i < this.step1.dependents.length; i++) {
                
                this.step1.dependents[i].relationship = this.step1.dependents[i].relationship.name;
            }
            if(x.user.medical){
              this.step2.frequency=x.user.medical.frequency;
              this.step2.doctors_visit=x.user.medical.doctors_visit;
            }
            
            if(x.user.policy) {
              var d = x.user.policy.date_of_issue;
              d = moment(d).format('YYYY-MM-DD');
              this.step3.date_of_issue = d;
              this.step3.policy_number = x.user.policy.policy_number;
              this.step3.value = x.user.policy.value;
              this.step3.country = x.user.policy.country;
              this.step3.insurance_carrier=x.user.policy.insurance_carrier;
              this.step3.deductible=x.user.policy.deductible;
            }
      
            this.step2.id=(x.user.medical ? x.user.medical.id : 0 )
          }
          
        },x=>this.loading=false);
      }
    }
    
    changeFiles(){
    
    }

    goBack(stepper: MatStepper){
      (stepper as any)._steps._results[(stepper as any)._selectedIndex - 1]._editable = true;
      (stepper as any)._steps._results[(stepper as any)._selectedIndex - 1]._customCompleted = false;
      stepper.previous();
    }
    
    moveTo(index) {
      var $this = this;
        if(index==1) {
          this.loading=true;  
          firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
          .then( data => {
          
            $this.fuid= data.user.uid;
            $this.firebaseuid = data.user.uid;
            
            if(this.role=="1") {
                $this.loading=false;
                (<any>$this.stepper).selected.completed = true;
                (<any>$this.stepper).selected.editable = false;
                (<any>$this.stepper).selectedIndex = index;
            }
            else {
                var admin = {
                    "name":this.name,
                    "lastname":this.lastname,
                    "email":this.email,
                    "firebase_uid":$this.firebaseuid,
                    "role":this.role
                }
                this.httpServices.postHttpAuth("/admin/register",admin)
                .subscribe(x => {
                  this.loading=false;
                  this.router.navigate(['/users']);
                  this.toastr.success("Datos registrados de manera correcta",'Proceso exitoso',{
                    closeButton: false  
                  });
                },x=>this.loading=false) 
            }

          })
          .catch(function(error) {
            $this.loading=false;
            alert(error.message);
            var errorCode = error.code;
            var errorMessage = error.message;
          });
        }
        else if(index==2) {
        
          var d = this.step1.birth_date;
          d = moment(d).format('YYYY-MM-DD');
          this.step1.birth_date = d;
          if(!this.isEditing){
            this.step1.fuid=this.firebaseuid;
            this.step1.email = this.email;
            this.loading=true;
            this.httpServices.postHttpAuth("/register",this.step1)
            .subscribe(x => {
              this.loading=false;
              (<any>$this.stepper).selected.completed = true;
              (<any>$this.stepper).selected.editable = false;
              (<any>$this.stepper).selectedIndex = 2;
              this.toastr.success("Datos registrados de manera correcta",'Proceso exitoso',{
                closeButton: false  
              });
            },x=>this.loading=false) 
          }
          else{
            this.loading=true;
            if(!this.birth_date_change){
              this.step1.birth_date = this.birthDateCorrection;
            }
            this.httpServices.putHttpAuth("/user/"+this.idSelected, this.step1)
            .subscribe(x=>{
              this.loading=false;
              (<any>$this.stepper).selected.completed = true;
              (<any>$this.stepper).selected.editable = false;
              (<any>$this.stepper).selectedIndex = 2;
              this.toastr.success("Datos registrados de manera correcta",'Proceso exitoso',{
                closeButton: false  
              });
              this.step1.birth_date = moment(moment(this.step1.birth_date).add(24, 'hours').calendar()).format('YYYY-MM-DD');
            },x=>this.loading=false);

          }
        }
        else if(index==3){
          
          if(!this.isEditing){
            this.step2.fuid=this.firebaseuid;
            this.loading=true;
            if(!this.hasPolicy) this.step2.has_policy=this.hasPolicy;
            
            this.httpServices.postHttpAuth("/register",this.step2)
            .subscribe(x => {
              this.loading=false;
            
              if(!this.hasPolicy){
                
                this.toastr.success("Cuenta registrada de manera correcta",'Proceso exitoso',{
                  closeButton: false  
                });
                this.router.navigate(['/users'])
                return 0;
              }
              (<any>$this.stepper).selected.completed = true;
              (<any>$this.stepper).selected.editable = false;
              (<any>$this.stepper).selectedIndex = 3;
              this.toastr.success("Datos registrados de manera correcta",'Proceso exitoso',{
                closeButton: false  
              });
            },x=>this.loading=false) 
          }
          else{
            this.step2.user_id=this.idSelected;
            this.loading=true;
            this.httpServices.putHttpAuth("/medical/"+this.step2.id, this.step2)
            .subscribe(x=>{
              this.loading=false;
              (<any>$this.stepper).selected.completed = true;
              (<any>$this.stepper).selected.editable = false;
              (<any>$this.stepper).selectedIndex = 3;
              this.toastr.success("Datos registrados de manera correcta",'Proceso exitoso',{
                closeButton: false  
              });
            },x=>this.loading=false);
          }
        }
        else if(index==4){
          this.step3.fuid=this.firebaseuid;
          var d = this.step3.date_of_issue;
          d = moment(d).format('YYYY-MM-DD');
          this.step3.date_of_issue = d;
          
          if(this.policyUrl && this.policyUrl.length > 0){
            this.step3.policy_files.type="url";
            this.step3.policy_files.url=this.policyUrl;
          }
          
          if(this.medicalRedUrl && this.medicalRedUrl.length > 0)
          {
            this.step3.medical_red.type = "url";
            this.step3.medical_red.url = this.medicalRedUrl;
          }  
          
          if(this.policyCarnetUrl && this.policyCarnetUrl.length > 0){
            this.step3.policy_carnet.type = "url";
            this.step3.policy_carnet.url = this.policyCarnetUrl;
          }
          if(!this.isEditing){
            this.loading=true;
            this.httpServices.postHttpAuth("/register",this.step3)
            .subscribe(x => {
              this.loading=false;
              (<any>$this.stepper).selected.completed = true;
              (<any>$this.stepper).selected.editable = false;
              this.toastr.success("Datos registrados de manera correcta",'Proceso exitoso',{
                closeButton: false  
              });
              this.router.navigate(['/users'])
            },x=>this.loading=false)  
          }
          else{
            
            
            
            
            if(this.user.policy){
              this.loading=true;
              this.httpServices.putHttpAuth("/policy/"+this.user.policy.id,this.step3)
              .subscribe(x => {
                this.loading=false;
                this.toastr.success("Poliza actualizada de manera correcta",'Proceso exitoso',{
                  closeButton: false  
                });
              },x=>this.loading=false);
            }else{
              this.step3.user_id=this.user.id;
              this.loading=true;
              this.httpServices.postHttpAuth("/policy",this.step3)
              .subscribe(x => {
                this.loading=false;
                this.toastr.success("Poliza registrada de manera correcta",'Proceso exitoso',{
                  closeButton: false  
                });
              },x=>this.loading=false);
            }
          }
    
          
        }
    }
  
    async onFileChange(event){
      
      var $event=event;
      
      if($event=='document'){
        var file = this.uploadedFiles;
      }
      else if($event=='carrier'){
        var file = this.uploadedFilesCarrier;
        this.step3.policy_files.type='file';
        if(!this.step3.policy_files.files)
          this.step3.policy_files.files=[];  
      }
      else if($event=='carrierCarnet'){
        this.step3.policy_carnet.type='file';
        var file = this.uploadedFilesCarrierCarnet;
        if(!this.step3.policy_carnet.files)
          this.step3.policy_carnet.files=[];
      }
      else if($event=='carrierRed'){
        var file = this.uploadedFilesRed;
        this.step3.medical_red.type='file';
        if(!this.step3.medical_red.files)
          this.step3.medical_red.files=[];
      }
      var $this = this;
      
      if(file && file.length > 0 ) {
        
        for (let i = 0; i < file.length; i++) {
          
            const fileReader = await this.fileRender(file[i]);
          
          
                if($event=='document'){
                  $this.step1.document.file = fileReader;
                  $this.step1.document.type = "file";
                }
                else if($event=='carrier'){
                  $this.step3.policy_files.type='file';
            
                  if(!$this.step3.policy_files.files)
                    $this.step3.policy_files.files=[];  
            
                  $this.step3.policy_files.files.push(fileReader);
                }
                else if($event=='carrierCarnet'){
                  $this.step3.policy_carnet.type='file';
                  if(!$this.step3.policy_carnet.files)
                    $this.step3.policy_carnet.files=[];  
                    
                  $this.step3.policy_carnet.files.push(fileReader);
                }
                else if($event=='carrierRed'){
                  $this.step3.medical_red.type='file';
                  
                  if(!$this.step3.medical_red.files)
                    $this.step3.medical_red.files=[];
                    
                  $this.step3.medical_red.files.push(fileReader);
                }
                
            // }
        }
        
      }


    }

    
    fileRender(inputFile):any {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
    };

    onChangeProvince(provinceId: number) {
      this.httpServices.getHttpAuth("/provinces/" + provinceId)
        .subscribe(response => {
          this.districts = response;
          this.corrections = [];
        });
    }

    onChangeDistrict(districtId: number) {
      this.httpServices.getHttpAuth("/districts/" + districtId)
        .subscribe(response => {
          this.corrections = response;
        });
    }

    addDependent(){
      (<any>$('#modalDependent')).modal('show');
    }
    
    addListDependent(){
      this.editingDependent=false;
      var d = this.dependent.birth_date;
      d = moment(d).format('YYYY-MM-DD');
      this.dependent.birth_date=d;
      
      this.dependent.relationship = this.dependents.find(dep => {
        if(dep.id==this.dependent.relationship_id){
          return dep.name;
        }
      });
      if(this.isEditing){
        this.dependent.user_id = this.user.id;
        
        
        this.loading=true;
        this.httpServices.postHttpAuth("/dependent",this.dependent)
        .subscribe(x => {
          this.loading=false;
          var d = this.dependent.birth_date;
          d = moment(d);
          this.dependent.birth_date=d;
          this.dependent.relationship = this.dependent.relationship.name;
          this.step1.dependents.push(Object.assign({}, this.dependent));
          (<any>$('#modalDependent')).modal('hide');
          this.toastr.success("Dependiente agregado de manera correcta",'Proceso exitoso',{
            closeButton: false  
          });
        },x=>this.loading=false);
      }
      else{
        this.dependent.relationship = this.dependent.relationship.name;
        this.step1.dependents.push(Object.assign({}, this.dependent)); 
        (<any>$('#modalDependent')).modal('hide'); 
      }
      
    }
    
    deleteDependent(i){
      if(this.isEditing){
        this.loading=true;
        this.httpServices.deleteHttpAuth("/dependent/"+this.dependent.id,this.dependent)
        .subscribe(x => {
          this.loading=false;
          this.step1.dependents.splice( i, 1 );  
          (<any>$('#modalDependent')).modal('hide');
          this.toastr.success("Dependiente eliminado de manera correcta",'Proceso exitoso',{
            closeButton: false  
          });
        },x=>this.loading=false);
      }
      else{
        this.step1.dependents.splice( i, 1 );  
        (<any>$('#modalDependent')).modal('hide');
      }
      
    }
    
    editDependent(i){
        this.editingDependent=true;
        this.dependent = this.step1.dependents[i];
        (<any>$('#modalDependent')).modal('show');
    }
    
    postEditDependent(){
      var d = this.dependent.birth_date;
      d = moment(d).format('YYYY-MM-DD');
      this.dependent.birth_date=d;
      this.loading=true;
      this.httpServices.putHttpAuth("/dependent/"+this.dependent.id,this.dependent)
      .subscribe(x => {
        this.loading=false;
        var d = this.dependent.birth_date;
        d = moment(d);
        this.dependent.birth_date=d;
        this.dependent.relationship = x.dependent.relationship.name;
        (<any>$('#modalDependent')).modal('hide');
        this.toastr.success("Dependiente editado de manera correcta",'Proceso exitoso',{
          closeButton: false  
        });
      },x=>this.loading=false);
    }
    
    getType(file):any {
      let type = file.split(/\#|\?/)[0].split('.').pop().trim();
      if (["png", "jpg", "jpeg", "gif"].includes(type)) return "image";
      else if(["pdf","doc","docx"].includes(type)) return "document";
      else return "browser";
    }
    
    addUrlToForm(type){
        
      if(type=='policy' && this.policyUrl.length > 0){
        this.step3.policy_files.type="url";
        this.step3.policy_files.url=this.policyUrl;
      }
      else if(type=='policy' && this.policyUrl.length == 0) {
        this.step3.policy_files={};
      }
      else if(type=='carnet' && this.policyCarnetUrl.length > 0){
        this.step3.policy_carnet.type = "url";
        this.step3.policy_carnet.url = this.policyCarnetUrl;
      }
      else if(type=='carnet' && this.policyCarnetUrl.length == 0) {
        this.step3.policy_carnet={};
      }
      else if(type=='red' && this.medicalRedUrl.length > 0){
        this.step3.medical_red.type = "url";
        this.step3.medical_red.url = this.medicalRedUrl;
      }
      else if(type=='red' && this.medicalRedUrl.length == 0) {
        this.step3.medical_red={};
      }
    }
    
    deletePolicyMedia(id){
      
      this.loading=true;
      this.httpServices.deleteHttpAuth("/media/policy/"+id,{}).
      subscribe(x => {
        this.loading=false;
        this.user.policy.files = x.data;
        this.toastr.success("Archivo eliminado de manera correcta",'Proceso exitoso',{
          closeButton: false  
        });
      }, err => this.loading=false );
    }
}
