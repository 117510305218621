import { Component, OnInit, AfterViewInit, ViewChildren , QueryList } from '@angular/core';
import { HttpService } from './../../services/Http/http.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {  
  @ViewChildren(DataTableDirective) dtElements: QueryList<DataTableDirective>

  last_transactions: any = [];
  last_invoices: any = [];
  dashboarData: any;
  dtOptions: DataTables.Settings[] = [];
  dtTrigger: Subject<any>[] = [new Subject(), new Subject()];

  constructor(private httpServices: HttpService) { }

  ngOnInit() {

    this.dtOptions[0] = this.buildDtOptions("https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json", "Planes");
    this.dtOptions[1] = this.buildDtOptions("https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json", "Usuarios");

    this.httpServices.getHttpAuth("/admin/dashboard")
    .subscribe( x => {
      this.last_transactions = x.last_transactions;
      this.last_invoices = x.last_invoices;
      this.dashboarData = x;
      this.rerender();
    });

  }

  ngAfterViewInit(): void {
    this.dtTrigger[0].next();
    this.dtTrigger[1].next();
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective, index: number) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger[index].next();
      });
    });
  }

  private buildDtOptions(url: string, name: string){
    return {
      pagingType: 'full_numbers',
      language: {
        "url": url,
      },
      pageLength: 5,
      lengthChange: false,
      info: false,
      buttons: false,
    };
  }

  getStatus(status){
    if(status == 1) return "Autorizado";
    if(status == 2) return "Anulada";
    if(status == 3) return "Pendiente";
    if(status == 4) return "Rehazado";
  }

  isString(p){
    if(typeof p == 'string') return true;
    return false;
  }

  getClass(p){
    if(p>0){
      return "text-success";
    } else if(p<0){
      return "text-danger";
    }else{
      return "text-normal";
    }
  }

  getIcon(p){
    if(p>0){
      return "fa fa-long-arrow-up";
    } else if(p<0){
      return "fa fa-long-arrow-down";
    }else{
      return "fa fa-long-arrow-right";
    }
  }

  getText(p){
    if(p>0){
      return "Incrementó.";
    } else if(p<0){
      return "Decreció.";
    }else{
      return "Se mantuvo.";
    }
  }

  getNumber(p){
    if(p<0) p = p*-1;
    return p;
  }
}
