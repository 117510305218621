import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	ElementRef
} from '@angular/core';
import { HttpService } from './../../services/Http/http.service';

import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Conditional } from '@angular/compiler';

@Component({
	selector: 'app-medication',
	templateUrl: './medication.component.html',
	styleUrls: ['./medication.component.scss']
})
export class MedicationComponent implements OnInit {
	constructor(
		public httpServices: HttpService,
		public toastr: ToastrService
	) {}
	data: any = [];
	dataModal = [];
	dataModalRecipes = [];
	status_data: boolean = false;
	@ViewChild('exampleModal') modal: ElementRef;
	@ViewChild(DataTableDirective)
	dtElement: DataTableDirective;
	dtOptions: any = {
		pagingType: 'full_numbers',
		language: {
			url: 'https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json'
		},
		pageLength: 10,
		order: [[0, 'desc']]
		// dom: 'Bfrtip',
		// buttons: [
		//     { extend: 'excel', title: 'Reporte de solicitudes de medicina', exportOptions:{
		//       stripNewlines: false
		//     }
		//     }
		// ]
	};
	dtTrigger: Subject<any> = new Subject();

	ngOnInit() {
		this.httpServices.getHttpAuth('/medicationrequest').subscribe(x => {
			var medicines = [];
			for (var i = 0; i <= x.medicinas.length; i++) {
				if (
					x.medicinas[i] === undefined ||
					x.medicinas[i] === 'undefined' ||
					x.medicinas[i] === null
				) {
				} else {
					// if(x.medicinas[i].type !== null){

					// }
					medicines.push(x.medicinas[i]);
				}
			}
			console.log(medicines);
			this.data = medicines;

			this.rerender();
		});
	}

	public isPdf(url: string): boolean {
		const isPdf = /pdf$/.test(url);
		console.log(isPdf);

		return isPdf;
	}

	changeStatus(id, data) {
		this.httpServices
			.putHttpAuth('/medicationrequest/' + id, {
				status: data
			})
			.subscribe(x => {
				this.toastr.success(
					'Estado actualizado de manera correcta',
					'Proceso exitoso',
					{
						closeButton: false
					}
				);
			});
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
	}

	rerender(): void {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			// Destroy the table first
			dtInstance.destroy();
			// Call the dtTrigger to rerender again
			this.dtTrigger.next();
		});
	}

	DetailMedication(data) {
		this.modal.nativeElement.classList.remove('hidden');
		this.modal.nativeElement.classList.add('show');

		this.modal.nativeElement.style.display = 'block';
		if (data.medicines.length > 0) {
			this.status_data = false;
			console.log(data);
			// data modal
			for (let index = 0; index < data.medicines.length; index++) {
				let medicine: any = {
					name: data.medicines[index].name,
					quantity:
						(data.medicines[index].quantities_medicines &&
							data.medicines[index].quantities_medicines
								.quantity) ||
						data.medicines[index].quantity,
					type: data.medicines[index].type,
					unit:
						data.medicines[index].unit_measurement &&
						data.medicines[index].unit_measurement.name,
					range: data.medicines[index].range,
					milligrams: data.medicines[index].milligrams
				};

				this.dataModal.push(medicine);
			}
			this.dataModalRecipes = data.recipes.map(solicitud => {
				if (solicitud.medical_order_image == null) {
					return {
						...solicitud
					};
				} else {
					let recipe: any = {
						value: solicitud.medical_order_image
					};

					if (this.isPdf(solicitud.medical_order_image)) {
						recipe.label = 'pdf';
					} else recipe.label = 'img';

					return {
						...solicitud,
						medical_order_image: recipe
					};
				}
			});
		} else {
			this.status_data = true;
		}
	}

	CloseModal() {
		this.modal.nativeElement.classList.add('hidden');
		this.modal.nativeElement.classList.remove('show');
		// this.modal.nativeElement.classList.remove('modal-open')
		this.modal.nativeElement.style.display = 'none';
		this.dataModal = [];
	}
}
