import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from "./../../services/Http/http.service"
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router,private httpServices: HttpService) { }
  app : any = null;
  email: String = "";
  password: String = "";
  loading: boolean = false;
  ngOnInit() {
    
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp({
        apiKey: "AIzaSyCq7gSF0zFaxHX9IgpZ4MC3KMgvoUisc70",
        authDomain: "ama-qa.firebaseapp.com",
        databaseURL: "https://ama-qa.firebaseio.com",
        projectId: "ama-qa",
        storageBucket: "ama-qa.appspot.com",
        messagingSenderId: "737315672349"
      });
    }
    
  }
  
  login(){
      var $this = this;
      this.loading = true;
      this.app.auth().signInWithEmailAndPassword(this.email, this.password)
     .then(x=>{
       
       
       
       localStorage.setItem("token",x.user._lat);
       this.httpServices.getHttpAuth("/auth/user").
       subscribe(x=>{
         $this.loading=false;
         if(x.user.role_id== 1) {
           alert("Este es un espacio reservado para los administradores de Ama App");
           //window.location.href = "http://localhost:4001/";
           return 0;
         }
         
         if(x.user.status_account==6){
             alert('Usuario desactivado');
             return 0;
         }
         
         localStorage.setItem("role",x.user.role_id);
         this.router.navigate(['/dashboard'])
       }, x=>$this.loading=false);
       

     })
     .catch(function(error) {
       $this.loading=false
       var errorCode = error.code;
       alert((error.code == 'auth/user-not-found' ? "Usuario o password incorrecto" : error.code));
       var errorMessage = error.message;
     });
 }

}
