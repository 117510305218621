import { Component, OnInit,AfterViewInit,ViewChild } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  constructor(public httpServices: HttpService, public toastr: ToastrService) { }
  data: any = []


  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: any = {
      pagingType: 'full_numbers',
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
      },
      "pageLength": 10,
      dom: 'Bfrtip',
      buttons: [
          { extend: 'excel', title: 'Reporte de solicitud de facturas', exportOptions:{
            stripNewlines: false
          }
          }
      ] 
    };
  dtTrigger: Subject<any> = new Subject();;
  
  ngOnInit() {
    this.httpServices.getHttpAuth("/invoices")
    .subscribe( x => {
        this.data = x.invoices;
          this.rerender();
    });
  }
  
  
  changeStatus(id, data){
    
    this.httpServices.putHttpAuth("/invoices/"+id,{
      status:data
    }).subscribe(x => {
      this.toastr.success("Estado actualizado de manera correcta",'Proceso exitoso',{
        closeButton: false  
      });
    })
  }
  

  ngAfterViewInit(): void {
  this.dtTrigger.next();
  }
  rerender(): void {
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
   // Destroy the table first
   dtInstance.destroy();
   // Call the dtTrigger to rerender again
   this.dtTrigger.next();
  });
  }
}
