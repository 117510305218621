import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { environment } from '@env/environment';


@Component({
  selector: 'app-issuance-documents',
  templateUrl: './issuance-documents.component.html',
  styleUrls: ['./issuance-documents.component.scss']
})
export class IssuanceDocumentsComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  documents = [];
  typesDoc = [];
  statusDoc = [];
  formEmail: FormGroup;
  submittedEmail = false;
  formAnular: FormGroup;
  submittedAnular = false;
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Reporte de Facturas Electrónicas', 
        exportOptions:{
          stripNewlines: false,
          format: {
            format: {
              body: function(data, row, column, node) {              
                if(column==6)
                return data.replace('$', '');
              else if(column==4)
                return data.replace(/<\/?[^>]+(>|$)/g, "");
              else 
               return data;
              }
            },
          },
          columns: ':not(.notexport)'
        }
      }
    ]
  };
  dtTrigger: Subject<any> = new Subject();

  base_api: string = environment.url_api;

  searchFilter = {
    start_date: '',
    end_date:  '',
    type:  '',
    ndoc:  '',
    client:  '',
    status:  '',
  }

  dataEmail={id:'',email:''};
  loading: boolean = false;
  loadingEmail: boolean = false;
  
  dataCancel={id:'',num:'',reason:''};


  constructor(
    private httpServices: HttpService, 
    public toastr: ToastrService, 
    public datepipe: DatePipe,
    private fb: FormBuilder) { 

      this.createFormEmail();
      this.createFormAnular();

    }

  get e(): any { return this.formEmail.controls; }
  get a(): any { return this.formAnular.controls; }
 
  ngOnInit() {
    this.loadDocuments();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }


  private createFormEmail(): void {
    this.formEmail = this.fb.group(
      {
        email: [ '', [ Validators.required, Validators.email ]  ],
      }
    );
  }

  private createFormAnular(): void {
    this.formAnular = this.fb.group(
      {
        canceled_reason: [ '', [ Validators.required ]  ],
      }
    );
  }

  onSubmitEmail() {
    this.submittedEmail = true;
    if(this.formEmail.valid) {
      this.loadingEmail = true;
      this.httpServices.postHttpAuth('/documents/'+this.dataEmail.id+'/mail',this.formEmail.value)
      .subscribe( x => {
        //console.log(x);
        this.toastr.success('Correo enviado exito');
        (<any>$('#modalSendMail')).modal('hide');
        this.submittedEmail = false;
        this.loadingEmail = false;
      }, err => {
        this.loadingEmail = false;
      }
      );
    }

  }

  loadDocuments() {

    let act_date = new Date();
    const start = act_date.toISOString().split('T')[0];
    const end = act_date.toISOString().split('T')[0];

    let cadena = '';
    cadena = cadena+'&start_date='+start;
    cadena = cadena+'&end_date='+end;

    //this.httpServices.getHttpAuth("/documents?paginate=1")
    this.httpServices.getHttpAuth("/documents?"+cadena+"&paginate=1")
    .subscribe( x => {
      this.documents = x.data;
      this.rerender();
    });
    this.httpServices.getHttpAuth("/extrainvoice-index")
    .subscribe( x => {
      this.statusDoc = x.status_documents;
      this.typesDoc  = x.type_documents;
    });
  }

  onClear(){
    this.loadDocuments();
    this.searchFilter = {
      start_date: '',
      end_date:  '',
      type:  '',
      ndoc:  '',
      client:  '',
      status:  '',
    }
  }

  onSubmit() {
    const start = this.datepipe.transform(this.searchFilter.start_date, 'yyyy-MM-dd');
    const end = this.datepipe.transform(this.searchFilter.end_date, 'yyyy-MM-dd');

    let cadena = '';
 
    if(this.searchFilter.start_date!=='') {
      cadena = cadena+'&start_date='+start;
    }

    if(this.searchFilter.end_date!=='') {
      cadena = cadena+'&end_date='+end;
    }
    
    if(this.searchFilter.type!=='') {
      cadena = cadena+'&document_type='+this.searchFilter.type;
    }
    
    if( this.searchFilter.ndoc!=='') {
      cadena = cadena+'&document_number='+this.searchFilter.ndoc;
    }
    if( this.searchFilter.client!=='') {
      cadena = cadena+'&user='+this.searchFilter.client;
    }
    if( this.searchFilter.status!=='') {
      cadena = cadena+'&status='+this.searchFilter.status;
    }

    //console.log(cadena);
    if (cadena !== '') {
      //console.log(cadena);
      this.httpServices.getHttpAuth("/documents?"+cadena+"&paginate=1")
      .subscribe( x => {
        this.documents = x.data;
        this.rerender();
        //console.log(x);
      });
    } else {
      this.toastr.error('No hay datos para filtrar');
    }


  }


  openCancel(id,num){
    this.dataCancel.id = id;
    this.dataCancel.num = num;
    (<any>$('#modalCancel')).modal('show');
  }

  onSubmitAnular(){
    this.submittedAnular = true;
    if(this.formAnular.valid) {
      //console.log('es valido');
      this.loading = true;

      let data = {
        canceled_reason: this.dataCancel.reason
      }

      this.httpServices.postHttpAuth('/documents/' + this.dataCancel.id + '/canceled',data)
        .subscribe( x => {
          //console.log(x);
          this.toastr.success('Documento anulado con exito');
          this.loading = false;
          this.submittedAnular = false;
          this.loadDocuments();
          (<any>$('#modalCancel')).modal('hide');
      }, err => {
        this.loading = false;
      }
      );


    }
  }

  sendMail(id,email) {
    this.createFormEmail();

    this.dataEmail.email = email;
    this.dataEmail.id = id;
  
    (<any>$('#modalSendMail')).modal('show');
  }

  formReset() {
    this.formEmail.reset();
    this.submittedEmail = false;
  }

  formResetAnular(){
    this.formAnular.reset();
    this.submittedAnular = false;
  }

}
