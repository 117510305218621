import { Component, OnInit,ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  showDetails: boolean = false;
  detailsPayment: any;
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Reporte de transacciones', 
        exportOptions:{
          stripNewlines: false,
          format: {
              body: function(data, row, column, node) {              
                     if(column==6)
                       return data.replace('$', '');
                     else if(column==1)
                       return data.replace(/<\/?[^>]+(>|$)/g, "");
                     else 
                      return data;
              }
          },
          columns: ':not(.notexport)'
        }
      }
    ]
  };
  dtTrigger: Subject<any> = new Subject();
  products=[];
  selectedProduct={  id: '', description: '', code: '', type: '', itbms: 0,  price: ''};
  sendProduct=[];
  dataBase = { user_id: '', transaction_id: '', price : ''};
  loadingProduct: boolean = false;
  searchFilter = {
    start_date: this.datepipe.transform(Date(), 'yyyy-MM-dd'),
    end_date:  '',
  }
  
  constructor(public datepipe: DatePipe, private httpServices: HttpService, public toastr: ToastrService, private _formBuilder: FormBuilder) { }
  
  payments: Array<any> = [];
  async ngOnInit(): Promise<void> {
    //this.loadPayments();
    await this.loadProducts();
    await this.onFilter();
  }
  
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  onClear(){
    //this.loadPayments();
    this.payments = [];
    this.rerender();
    this.searchFilter = {
      start_date: '',
      end_date:  '',
    }
  }
  onFilter() {
    
    const start = this.datepipe.transform(this.searchFilter.start_date, 'yyyy-MM-dd');
    const end = this.datepipe.transform(this.searchFilter.end_date, 'yyyy-MM-dd');

    let cadena = '';
 
    if(this.searchFilter.start_date!=='') {
      cadena = cadena+'&start_date='+start;
    }

    if(this.searchFilter.end_date!=='') {
      cadena = cadena+'&end_date='+end;
    }
    

    if (cadena !== '') {
      this.payments = [];
      this.httpServices.getHttpAuth("/metropago/transactions?"+cadena)
      .subscribe( x => {
        this.payments = x.payments;
        this.rerender();
      });
    } else {
      this.toastr.error('No hay datos para filtrar');
    }


  }

  loadPayments() {
    this.httpServices.getHttpAuth("/metropago/transactions")
    .subscribe( x => {
        this.payments = x.payments;
        this.rerender();
    });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.clear().draw();
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  showPaymentDetails(transaction) {
      (<any>$('#modalDetails')).modal('show');
      this.showDetails = true;
      this.detailsPayment = transaction; 
  }
  
  backList(){
      this.showDetails = false;
  }

  openModalProduct(id, user, status, price) {
    if(status > 0) {
      
      if(user.client_id!==null) {
        this.dataBase = { user_id: user.id, transaction_id: id, price: price};
        (<any>$('#modalAddProduct')).modal('show');
      } else {
        this.toastr.error('Debe completar el registro del cliente para continuar');
      }

    } else {
      this.toastr.error('No puede cargar productos');
    }
    
  }

  formReset() {
    this.selectedProduct={  id: '', description: '', code: '', type: '', itbms: 0,  price: ''};
    this.sendProduct=[];
  }
  loadProducts() {
    this.httpServices.getHttpAuth("/products?paginate=1")
    .subscribe( x => {
      this.products = x.data;
      this.rerender();
    });
  }

  createDocument() {
    if(this.selectedProduct.code!=''){
      
      let precio = parseFloat(this.dataBase.price);
      let impuesto = 0;
      if (this.selectedProduct.itbms != 0) {
        impuesto = (precio * this.selectedProduct.itbms)/100;
      }
      let total = (impuesto + precio ) * 1;

      this.sendProduct.push({
        user_id: this.dataBase.user_id,
        transaction_id: this.dataBase.transaction_id,
        product_id: this.selectedProduct.id,
        code: this.selectedProduct.code,
        amount:1, 
        discount:0,
        description: this.selectedProduct.description,
        price: this.dataBase.price,
        item_price: this.dataBase.price,
        itbms: this.selectedProduct.itbms,
        value_itbms: impuesto,
        total: total,
      });

      console.log(this.sendProduct);
      //return false;
      this.loadingProduct = true;
      this.httpServices.postHttpAuth('/metropago/transactions', this.sendProduct[0])
      .subscribe( x => {
        //console.log(x);
        this.loadPayments();
        this.toastr.success('Transacción Facturada');
        (<any>$('#modalAddProduct')).modal('hide');
        this.loadingProduct = false;
        location.reload();
      }, err => {
        this.loadingProduct = false;
      }
      ); 


    } else {
      this.toastr.error('Debe seleccionar un producto');
    }
  }

  capitalize(txt){
    if(txt == '') return '';
    let ar   = txt.split(" ");
    let temp = [];
    ar.forEach(word =>{
      if(word !== ""){
        temp.push(word[0].toUpperCase() + (word.substring(1)).toLowerCase());
      }
    });
    return temp.join(" ");
  }

}
