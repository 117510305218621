import { Component, OnInit } from '@angular/core';
import { HttpService } from "./../../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-update-admin',
  templateUrl: './update-admin.component.html',
  styleUrls: ['./update-admin.component.scss']
})
export class UpdateAdminComponent implements OnInit {
    
  roleAuth: number = 1;
  idSelected: number = 0;
  loading: boolean = false;
  user: any = {};
  status_accounts: any = [
      {
          "id":1,
          "name":"activo"
      },
      {
          "id":6,
          "name":"Usuario inactivo / eliminado"
      }
  ];
  roles_account : any = [
      {
          "id":2,
          "name":"Administrador"
      },
      {
          "id":3,
          "name":"Administrador de Clientes"
      }, 
      {
           "id":4,
           "name":"Administrador de Pagos"
       },
       {
           "id":5,
           "name":"Administrador de Ordenes"
       }
  ]
  constructor(private router:Router,private _route: ActivatedRoute, private httpServices: HttpService, public toastr: ToastrService) { }

  ngOnInit() {
      this.roleAuth = parseInt(localStorage.getItem('role'));
      if(this.roleAuth!=2){
          this.router.navigate(['/users']);
          this.toastr.warning("Solo un usuario administrador puede realizar cambios a un usuario",'Acceso negado',{
            closeButton: false  
          });
      }
      
      if(this._route.snapshot.params.id){
        this.idSelected = this._route.snapshot.params.id;
        // this.loading=true;
        this.httpServices.getHttpAuth("/user/"+this.idSelected)
        .subscribe(x=>{
            this.user = x.user;
        });
      }
  }
  
  edit(){
      this.loading = true;
      this.httpServices.putHttpAuth("/admin/update/"+this.idSelected,this.user)
      .subscribe(x=>{
           this.loading = false;
          this.toastr.warning("El usuario se ha editado de manera correcta",'Administrador actualizado',{
            closeButton: false  
          });
      },x =>  this.loading = false);
  }

}
