import { Component, OnInit,ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "./../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-plans-component',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansListComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  showDetails: boolean = false;
  detailsPayment: any;
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Reporte de planes', 
        exportOptions:{
          stripNewlines: false,
          format: {
              body: function(data, row, column, node) {              
                     if(column==5)
                       return data.replace('$', '');
                     else if(column==8)
                       return data.replace(/<\/?[^>]+(>|$)/g, "");
                     else 
                      return data;
              }
          },
          columns: ':not(.notexport)'
        }
      }
    ]
  };
  dtTrigger: Subject<any> = new Subject();
  
  constructor(private httpServices: HttpService, public toastr: ToastrService, private _formBuilder: FormBuilder) { }
  
  plans: Array<any> = [];
  ngOnInit() {
    this.httpServices.getHttpAuth("/admin/subscription")
    .subscribe( x => {
        console.log(x);
        this.plans = x.data;
        this.rerender();
    });
  }
  
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
 

}
