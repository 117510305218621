import {
	Component,
	OnInit,
	ViewChild,
	AfterViewInit,
	ElementRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from './../../services/Http/http.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	@ViewChild('mgroup') tabPayment: ElementRef;
	@ViewChild('mgroupdata') tabData: ElementRef;

	dtOptions: any = {
		info: false,
		language: {
			"url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
		  },
		dom: 'Bfrtip',
		pagingType: 'full_numbers',
      	pageLength: 5,
		buttons: [
			{
				extend: 'excel',
				title: 'Reporte de usuarios',
				exportOptions: {
					stripNewlines: false,
					format: {
						body: function (data, row, column, node) {
							if (column === 6) {
								return data.replace('$', '');
							} else if (column === 1 || column === 2 || column === 3) {
								return data.replace(/<\/?[^>]+(>|$)/g, '');
							} else {
								return data;
							}
						}
					},
					columns: ':not(.notexport)'
				}
			}
		]
	};
	dtTrigger: Subject<any> = new Subject();
	paymentProcess: boolean = false;
	changeTypePayment: Subject<any> = new Subject();
	data: any = [];
	listDependets = [];
	policy: any = null;
	searchInput: string = '';
	debounce = null;

	/*--- paginación ---*/

	p_pagination = [{index: 1, class: "page-link active"}];
	p_page       = 1;
	p_last_page  = 1;
	p_from       = 1;
	p_to         = 1;
	p_per_page   = 10;
	p_total      = 1;

	/*--- /paginación ---*/

	/*---------------------------*/
	/*--- paginación de pagos ---*/
	/*---------------------------*/

	/*--- paginación de planes ---*/

	s_pagination = [{index: 1, class: "page-link active"}];
	s_page       = 1;
	s_last_page  = 1;
	s_from       = 1;
	s_to         = 1;
	s_per_page   = 5;
	s_total      = 1;

	/*--- /paginación de planes ---*/

	/*--- paginación de historico de pagos ---*/

	h_pagination = [{index: 1, class: "page-link active"}];
	h_page       = 1;
	h_last_page  = 1;
	h_from       = 1;
	h_to         = 1;
	h_per_page   = 5;
	h_total      = 1;

	/*--- /paginación de historico de pagos ---*/

	/*--- paginación de tarjetas de usuario ---*/

	t_pagination = [{index: 1, class: "page-link active"}];
	t_page       = 1;
	t_last_page  = 1;
	t_from       = 1;
	t_to         = 1;
	t_per_page   = 5;
	t_total      = 1;

	/*--- /paginación de tarjetas de usuario ---*/

	/*----------------------------*/
	/*--- /paginación de pagos ---*/
	/*----------------------------*/

	totalPages: number = 0;
	medical: any = null;
	selectedCreditCard: string = '0';
	document: string = '';
	userSelected: any = null;
	typeOfPlan: number = 1;
	amountPlan: number = 60;
	selectedIndexPolicy: number = 0;
	selectedIndex: number = 0;
	public uploadedFiles: Array<File> = [];
	userSelectedPosition: number = null;
	formPayment: boolean = false;
	paymentLoader: any;
	creditCard: Object = {};
	newCreditCard: any = {};
	isLinear = false;
	firstFormGroup: FormGroup;
	secondFormGroup: FormGroup;
	public loading = false;
	amountPayment: any;
	nextPayment: any;
	paymentDate: any;
	role: number = 2;
	listType?: string = 'users';
	editCardMode: boolean = true;
	plans = [];
	userPlan = [];
	selected = new FormControl(0);
	showDetails: boolean = false;
	detailsPayment: any;
	statues = [
		'Registro incompleto',
		'Usuario activo',
		'Usuario sin plan',
		'Esperando pago',
		'Usuario moroso',
		'Bloqueado por pago',
		'Usuario eliminado'
	];
	statues_plan = [
		'activo',
		'moroso',
		'suspendido',
		'esperando pago',
		'cancelado'
	];
	edit_row = [];
	edit_pagos_dia = [];
	edit_pagos_proximo = [];
	edit_pagos_status = [];

	constructor(
		private httpServices: HttpService,
		public toastr: ToastrService,
		private _formBuilder: FormBuilder,
		private route: ActivatedRoute
	) {}

	ngOnInit() {
		this.paymentLoader = {
			color: 'primary',
			mode: 'indeterminate',
			value: 100,
			isLoading: false
		};

		if (this.route.snapshot.params['type']) {
			this.listType = this.route.snapshot.params['type'];
		}

		this.role = parseInt(localStorage.getItem('role'));
		(<any>this.tabPayment)._selectedIndex = 0;
		this.firstFormGroup = this._formBuilder.group({
			firstCtrl: ['', Validators.required]
		});
		this.secondFormGroup = this._formBuilder.group({
			secondCtrl: ['', Validators.required]
		});
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
		if (this.listType === 'users') {
			this.loadUsers();
		} else {
			this.loadAllAdmins();
		}
	}

	rerender(): void {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
		  // Destroy the table first
		  dtInstance.destroy();
		  // Call the dtTrigger to rerender again
		  this.dtTrigger.next();
		});
	}

	editStatus(o) {
		this.edit_row[o.id] = true;
	}

	saveStatus(o) {
		this.loading = true;
		this.httpServices.putHttpAuth('/user/' + o.id, o).subscribe(
			x => {
				this.loading = false;
				this.edit_row[o.id] = false;
				this.toastr.success(
					'Datos actualizados de manera correcta',
					'Proceso exitoso',
					{
						closeButton: false
					}
				);
			},
			x => (this.loading = false)
		);
	}

	editPlanStatus(o) {
		this.edit_pagos_status[o.id] = true;
	}

	savePlanStatus(o, user_card_id) {
		this.loading = true;
		console.log('o: ', o);
		console.log('user_card_id: ', user_card_id);
		o.user_card_id = user_card_id;
		this.httpServices.putHttpAuth('/payment/user/' + o.id, o).subscribe(
			x => {
				o.plan_status = x.plan_status;
				this.loading = false;
				this.edit_pagos_status[o.id] = false;
				this.edit_pagos_dia[o.id] = false;
				this.edit_pagos_proximo[o.id] = false;

				this.toastr.success(
					'Datos actualizados de manera correcta',
					'Proceso exitoso',
					{
						closeButton: false
					}
				);
				if (
					o.user_card_id == 1 &&
					this.userSelected.cards.length == 0
				) {
					document.getElementById('register_plan').click();
				}
			},
			x => {
				this.loading = false;
				console.log(x);
			}
		);
	}

	editPlanDate(o) {
		this.edit_pagos_dia[o.id] = true;
	}

	editPlanNext(o) {
		this.edit_pagos_proximo[o.id] = true;
	}

	openDependents(dependent) {
		this.listDependets = this.data[dependent].dependents;
		if (this.listDependets.length === 0) {
			this.toastr.info('No existen dependientes asociados', 'Info!', {
				closeButton: false
			});
			return 0;
		}
		this.userSelectedPosition = dependent;
		(<any>$('#exampleModal')).modal('show');
	}

	documents(i) {
		this.medical = null;
		this.document = this.data[i].document_url;

		this.userSelectedPosition = i;
		this.policy = this.data[i].policy;
		this.userSelected = this.data[i];
		this.loading = true;
		this.httpServices
			.getHttpAuth('/user/' + this.userSelected.id)
			.subscribe(
				x => {
					this.userSelectedPosition = i;
					this.userSelected = x.user;
					this.data[i] = x.user;
					let frequencies = <any>[
						{
							type: 'daily',
							label: 'Todos los días'
						},
						{
							type: 'weekly',
							label: 'Semanalmente'
						},
						{
							type: 'each-15-days',
							label: 'Cada 15 días'
						},
						{
							type: 'every-month',
							label: 'Una vez al mes'
						},
						{
							type: 'one-year-a-go',
							label: 'Una vez al año'
						},
						{
							label: 'Dos veces al año',
							type: 'two-times-a-year'
						}
					];

					var frequency = frequencies.find(freq => {
						if (freq.type === this.data[i].medical.frequency) {
							return freq.label;
						}
					});

					this.medical = this.data[i].medical;
					this.medical.frequency = frequency;

					(<any>$('#modalPolicy')).modal('show');
					this.loading = false;
				},
				err => (this.loading = false)
			);
	}

	plan(i) {
		this.loading = true;
		this.formPayment = false;
		this.userSelected = this.data[i];
		this.editCardMode = false;
		this.selected.setValue(0);
		console.log(this.userSelected);
		this.httpServices
			.getHttpAuth('/subscription/user/' + this.userSelected.id)
			.subscribe(
				x => {
					this.selected.setValue(0);
					// console.log(x.user.plans);
					this.userPlan = x.user.plans;
					//this.rerender();

					this.userSelected.payments = x.user.payments;
					this.paginationUpdate('planes');
					this.paginationUpdate('historico');
					this.paginationUpdate('tarjetas');
					(<any>$('#paymentUser')).modal('show');
					this.loading = false;
				},
				err => (this.loading = false)
			);
	}

	doPayment(type, data?) {
		this.selected.setValue(3);

		if (type === 'new') {
			data = {
				user_id: this.userSelected.id,
				user_card_id:
					(this.userPlan[0] && this.userPlan[0].user_card_id) ||
					'no-card'
			};
		}

		this.changeTypePayment.next({
			type,
			data
		});
	}

	showPaymentDetails(transaction) {
		// console.log(transaction);
		this.showDetails = true;
		this.detailsPayment = transaction;
	}

	backList() {
		this.showDetails = false;
	}

	tabChangePayment(value) {
		if (value === 1) {
			this.showDetails = false;
		}
	}

	editPayment() {
		this.loading = true;

		let data = <any>{
			price: this.amountPayment
		};

		if (this.paymentDate && this.paymentDate != null) {
			data.paymentDate = this.paymentDate;
		}

		this.httpServices
			.putHttpAuth(
				'/order/payment/user/' +
					this.data[this.userSelectedPosition].payment.id,
				data
			)
			.subscribe(
				x => {
					this.loading = false;
					(<any>$('#paymentUser')).modal('hide');
					this.toastr.success(
						'Pago editado de manera correcta',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => (this.loading = false)
			);
	}

	disableSubscription(plan) {
		this.loading = true;
		console.log(plan);
		this.isLoading({
			isLoading: true,
			isFinish: false
		});

		this.httpServices
			.putHttpAuth('/order/payment/disable', {
				id_payment_plan: plan
			})
			.subscribe(
				x => {
					this.isLoading({
						isLoading: false,
						isFinish: true
					});
					(<any>$('#paymentUser')).modal('hide');
					this.toastr.success(
						'Se ha deshabilitado el pago recurrente de este usuario',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => {
					this.loading = false;
					this.isLoading({
						isLoading: false,
						isFinish: false
					});
				}
			);
	}

	changePaymentType(id) {
		console.log('plan ' + id);
	}

	addUser() {
		(<any>$('#newUser')).modal('show');
	}

	requestPayment() {
		this.loading = true;
		var data = <any>{};
		if (this.selectedCreditCard === '0') {
			data.creditCard = this.creditCard;
			data.user_id = this.userSelected.id;
		} else {
			data.card_id = this.selectedCreditCard;
			data.user_id = this.userSelected.id;
		}
		this.httpServices
			.postHttpAuth('/metropago/card/payama', data)
			.subscribe(
				x => {
					this.loading = false;
					this.data[this.userSelectedPosition] = x.user;
					this.rerender();
					(<any>$('#paymentUser')).modal('hide');
					this.toastr.success(
						'Pago procesado correctamente',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => (this.loading = false)
			);
	}

	loadAllUser(): void {
		this.data = [];
		//this.rerender();
		this.httpServices.getHttpAuth('/user?page=1').subscribe(x => {
			this.data = x.users.data;
			console.log(this.data);
			this.rerender();
		});
	}

	changeFilter(): void{
		clearTimeout(this.debounce);
	    this.debounce = setTimeout(() => {
			this.p_page = 1;
			this.loadUsers();
	    }, 1500);
	}

	loadUsers(temp = null): void {
		this.loading = true;
		//this.rerender();
		this.httpServices.getHttpAuth('/user?page='+this.p_page+'&query='+this.searchInput).subscribe(x => {			
			this.loading = false;
			this.data         = x.users.data;
			this.p_page       = x.users.current_page;
			this.p_last_page  = x.users.last_page;
			this.p_from       = x.users.from;
			this.p_to         = x.users.to;
			this.p_per_page   = x.users.per_page;
			this.p_total      = x.users.total;
			//this.rerender();
			this.updatePagination();
		}, err =>{
			if(temp != null) this.p_page = temp;
			this.loading = false;
		});
	}

	loadAllAdmins(): void {
		this.data = [];
		this.loading = true;
		//this.rerender();
		this.httpServices.getHttpAuth('/admin/get').subscribe(x => {
			this.loading = false;
			this.data = x.users;
			this.updatePagination();
			//this.rerender();
		}, err => 
			(this.loading = false)
		);
	}

	createCreditCard() {
		this.loading = true;
		var data = <any>{};
		data.creditCard = this.newCreditCard;
		data.user_id = this.userSelected.id;

		this.httpServices
			.postHttpAuth('/metropago/card/create', data)
			.subscribe(
				x => {
					this.loading = false;
					this.newCreditCard.name = '';
					this.newCreditCard.cvv = '';
					this.newCreditCard.date_of_issue = '';
					this.newCreditCard.card_number = '';
					(<any>$('#paymentUser')).modal('hide');
					this.toastr.success(
						'Tarjeta creada exitosamente',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => (this.loading = false)
			);
	}

	principalCard(id) {
		var data = <any>{};
		data.id = id;
		data.user_id = this.userSelected.id;
		this.httpServices
			.postHttpAuth('/metropago/card/principal', data)
			.subscribe(
				x => {
					this.loading = false;
					(<any>$('#paymentUser')).modal('hide');
					this.toastr.success(
						'Tarjeta modificada, todos los nuevos pagos se realizaran de la tarjeta seleccionada',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => (this.loading = false)
			);
	}

	editCard(id, digits) {
		this.editCardMode = true;
		this.newCreditCard.card_number = digits;
		this.newCreditCard.id = id;
	}

	cancelEdit() {
		this.editCardMode = false;
		this.newCreditCard.card_number = '';
		this.newCreditCard.id = null;
	}

	updateCreditCard() {
		this.loading = true;
		var data = <any>{};
		data.creditCard = this.newCreditCard;
		data.user_id = this.userSelected.id;
		data.card_id = this.newCreditCard.id;
		//delete data.creditCard.card_number;
		this.httpServices.putHttpAuth('/metropago/card/update', data).subscribe(
			x => {
				this.loading = false;
				this.editCardMode = false;
				this.newCreditCard.name = '';
				this.newCreditCard.cvv = '';
				this.newCreditCard.date_of_issue = '';
				this.newCreditCard.card_number = '';
				(<any>$('#paymentUser')).modal('hide');
				this.toastr.success(
					'Tarjeta modificada exitosamente',
					'Proceso exitoso',
					{
						closeButton: false
					}
				);
			},
			err => (this.loading = false)
		);
	}

	getType(file): any {
		let type = file.split(/\#|\?/)[0].split('.').pop().trim();
		if (['png', 'jpg', 'jpeg', 'gif'].includes(type)) {
			return 'image';
		} else if (['pdf', 'doc', 'docx'].includes(type)) {
			return 'document';
		} else {
			return 'browser';
		}
	}

	calculateClasses(user) {
		return {
			badge: true,
			'badge-pill': true,
			'badge-secondary': user.status_account === 0,
			'badge-success': user.status_account === 1,
			'badge-info': user.status_account === 2,
			'badge-primary': user.status_account === 3,
			'badge-warning': user.status_account === 4,
			'badge-danger': user.status_account === 5 || user.status_account === 6,
		};
	}

	getClass(status){
		return {
			badge: true,
			'badge-pill': true,
			'badge-success': status == 1,
			'badge-danger': status != 1,
		};
	}

	showStatus(user) {
		return user.status_account < this.statues.length
			? this.statues[user.status_account]
			: '';
	}

	setPricePlan(price) {
		this.amountPlan = price;
	}

	delete(user, index) {
		this.loading = true;
		this.httpServices.deleteHttpAuth('/user/' + user.id, {}).subscribe(
			x => {
				this.data[index] = x.user;
				this.loading = false;
				this.toastr.success(
					'Usuario desactivado de manera correcta',
					'Proceso exitoso',
					{
						closeButton: false
					}
				);
			},
			err => (this.loading = false)
		);
	}

	isLoading(event) {
		if (event.isLoading) {
			this.loading = true;
		} else {
			if (event.isFinish) {
				this.loading = false;
				this.selected.setValue(0);
				this.paymentLoader.isLoading = true;
				this.httpServices
					.getHttpAuth('/subscription/user/' + this.userSelected.id)
					.subscribe(
						x => {
							this.userPlan = x.user.plans;
							//this.rerender();
							(<any>$('#paymentUser')).modal('show');
							this.s_page = 1;					
							this.paginationUpdate('planes');
							this.paginationUpdate('historico');
							this.paginationUpdate('tarjetas');
							this.paymentLoader.isLoading = false;
						},
						err => {
							this.paymentLoader.isLoading = false;
							this.loading = false;
						}
					);
			} else {
				this.loading = false;
			}
		}
	}

	changePage(val, act){
		let temp = this.p_page;
		this.p_page = (act) ? this.p_page + val : this.p_page = val;
		if(this.listType == 'users'){
			this.loadUsers(temp);
		}else{
			this.updatePagination();
		}
	}

	updatePagination(){
		if(this.listType == 'users' && this.data.length){
			this.p_pagination = [];
			let start = 0;
			if(this.p_page <= 2){
				start = 1;
			} else if(this.p_page > 2 && (this.p_page+1) <= this.p_last_page){
				start = this.p_page - 1;
			} else if(this.p_page > 2 && (this.p_page+1) > this.p_last_page){
				start = this.p_page - 2;
			}
			for(var i=start; i <= this.p_last_page && this.p_pagination.length<3; i++){
				let data = {
					index: i,
					class: (i == this.p_page ? 'page-link active' : 'page-link'),
				}
				this.p_pagination.push(data);
			}
		}else if(this.data.length){
			this.p_pagination = [];
			this.p_per_page   = 10;
			this.p_last_page  = (this.data.length%this.p_per_page == 0) ? this.data.length/this.p_per_page : Math.floor(this.data.length/this.p_per_page) + 1;
			this.p_from       = (this.p_page == 1) ? this.p_page : this.p_page + this.p_per_page;
			this.p_to         = (this.data.length >= this.p_page+9) ? this.p_page + 9 : this.data.length;
			this.p_total      = this.data.length;
			let start = 0;
			if(this.p_page <= 2){
				start = 1;
			} else if(this.p_page > 2 && (this.p_page+1) <= this.p_last_page){
				start = this.p_page - 1;
			} else if(this.p_page > 2 && (this.p_page+1) > this.p_last_page){
				start = this.p_page - 2;
			}
			for(var i=start; i <= this.p_last_page && this.p_pagination.length<3; i++){
				let data = {
					index: i,
					class: (i == this.p_page ? 'page-link active' : 'page-link'),
				}
				this.p_pagination.push(data);
			}
		}
	}

	pageChange(val, act, list){
		if(list == 'planes'   ) this.s_page = (act) ? this.s_page + val : this.s_page = val;
		if(list == 'historico') this.h_page = (act) ? this.h_page + val : this.h_page = val;
		if(list == 'tarjetas' ) this.t_page = (act) ? this.t_page + val : this.t_page = val;
		this.paginationUpdate(list);
	}

	paginationUpdate(list){
		if(list == 'planes' && this.userPlan.length){
			this.s_pagination = [];
			this.s_per_page   = 5;
			this.s_last_page  = (this.userPlan.length%this.s_per_page == 0) ? this.userPlan.length/this.s_per_page : Math.floor(this.userPlan.length/this.s_per_page) + 1;
			this.s_from       = (this.s_page == 1) ? this.s_page : this.s_page + this.s_per_page;
			this.s_to         = (this.userPlan.length >= this.s_page+9) ? this.s_page + 9 : this.userPlan.length;
			this.s_total      = this.userPlan.length;
			let start = 0;
			if(this.s_page <= 2){
				start = 1;
			} else if(this.s_page > 2 && (this.s_page+1) <= this.s_last_page){
				start = this.s_page - 1;
			} else if(this.s_page > 2 && (this.s_page+1) > this.s_last_page){
				start = this.s_page - 2;
			}
			for(var i=start; i <= this.s_last_page && this.s_pagination.length<3; i++){
				let data = {
					index: i,
					class: (i == this.s_page ? 'page-link active' : 'page-link'),
				}
				this.s_pagination.push(data);
			}
		}else if(list == 'historico' && this.userSelected.payments.length){
			this.h_pagination = [];
			this.h_per_page   = 5;
			this.h_last_page  = (this.userSelected.payments.length%this.h_per_page == 0) ? this.userSelected.payments.length/this.h_per_page : Math.floor(this.userSelected.payments.length/this.h_per_page) + 1;
			this.h_from       = (this.h_page == 1) ? this.h_page : this.h_page + this.h_per_page;
			this.h_to         = (this.userSelected.payments.length >= this.h_page+9) ? this.h_page + 9 : this.userSelected.payments.length;
			this.h_total      = this.userSelected.payments.length;
			let start = 0;
			if(this.h_page <= 2){
				start = 1;
			} else if(this.h_page > 2 && (this.h_page+1) <= this.h_last_page){
				start = this.h_page - 1;
			} else if(this.h_page > 2 && (this.h_page+1) > this.h_last_page){
				start = this.h_page - 2;
			}
			for(var i=start; i <= this.h_last_page && this.h_pagination.length<3; i++){
				let data = {
					index: i,
					class: (i == this.h_page ? 'page-link active' : 'page-link'),
				}
				this.h_pagination.push(data);
			}
		}else if(list == 'tarjetas' && this.userSelected.cards.length){
			this.t_pagination = [];
			this.t_per_page   = 5;
			this.t_last_page  = (this.userSelected.cards.length%this.t_per_page == 0) ? this.userSelected.cards.length/this.t_per_page : Math.floor(this.userSelected.cards.length/this.t_per_page) + 1;
			this.t_from       = (this.t_page == 1) ? this.t_page : this.t_page + this.t_per_page;
			this.t_to         = (this.userSelected.cards.length >= this.t_page+9) ? this.t_page + 9 : this.userSelected.cards.length;
			this.t_total      = this.userSelected.cards.length;
			let start = 0;
			if(this.t_page <= 2){
				start = 1;
			} else if(this.t_page > 2 && (this.t_page+1) <= this.t_last_page){
				start = this.t_page - 1;
			} else if(this.t_page > 2 && (this.t_page+1) > this.t_last_page){
				start = this.t_page - 2;
			}
			for(var i=start; i <= this.t_last_page && this.t_pagination.length<3; i++){
				let data = {
					index: i,
					class: (i == this.t_page ? 'page-link active' : 'page-link'),
				}
				this.t_pagination.push(data);
			}
		}
	}
}
