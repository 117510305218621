import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { HttpService } from './../../services/Http/http.service';
import { ToastrService } from 'ngx-toastr';
import { MatSlideToggle } from '@angular/material/slide-toggle';

interface subscription {
	id?: number;
	user_id?: number;
	price: number;
	plan_id: number;
	frequency: string;
	type: string;
	payment?: payment_type;
}

interface payment_type {
	type: string;
	creditCard?: any;
	card_id?: any;
	data?: any;
	pay_now?: boolean;
}

@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
	constructor(
		private httpServices: HttpService,
		public toastr: ToastrService,
		private route: ActivatedRoute
	) {}

	plans = [];
	planTypes = [];
	plansByType = [];
	selectedPlan: any;
	body: subscription;
	typeSelected: number;
	policyType: number;
	paymentType: string;
	photo_data: Array<File> = [];
	selectedCreditCard: any = null;
	cardPlan: any = null;
	@Output() isLoading = new EventEmitter();
	// @Input() userPlan: any;
	@Input() changeTypePayment: Subject<any>;
	@Input() cards: any;
	selectedCard: any;
	isEditingCard = false;
	@ViewChild('payNow') payNow: MatSlideToggle;

	ngOnInit() {
		this.reset();

		this.changeTypePayment.subscribe(data => {
			console.log('changeTypePayment: ', data);
			this.isEditingCard = false;
			this.body.type = data.type;
			this.selectedCard = 0;
			this.photo_data = null;
			if (data.type == 'renovation') {
				if (data.data.user_card_id) {
					this.selectedCard = data.data.user_card_id;
					this.paymentType = 'card_token';
					this.body.payment = {
						type: 'card_token',
						card_id: this.selectedCard,
						pay_now: true,
					};					
					this.payNow.checked = true;
				} else {
					this.selectedCard = 'voluntary';
					this.changePaymentType('vol');
				}

				this.typeSelected = data.data.plan.plan_type_id;
				this.policyType = data.data.plan.plan_type_id;
				this.getPlans(this.typeSelected);

				let pl = this.planTypes.findIndex(
					data => data.id === this.typeSelected
				);
				this.body.id = data.data.id;
				this.body.price =
					data.data.payment_frequency == 'yearly'
						? data.data.price / this.planTypes[pl].monthly_fees
						: data.data.price;
				this.body.user_id = data.data.user_id;
				this.body.frequency = data.data.payment_frequency;
				this.body.plan_id = data.data.plan_id;
			} else {
				this.paymentType = 'credit_card';
				let user_card_id = data.data.user_card_id;
				this.body = {
					id: null,
					price: 0,
					plan_id: null,
					frequency: 'monthly',
					type: 'new',
					user_id: data.data.user_id,
					payment: {
						type: 'credit_card',
						creditCard: {
							name: null,
							cvv: null,
							card_number: null,
							date_of_issue: null
						}
					}
				};

				if (user_card_id > 0 || user_card_id === 'no-card') {
					this.selectedCard =
						user_card_id === 'no-card' ? null : user_card_id;
				} else {
					this.paymentType = 'vol';
					this.selectedCard = 'voluntary';
					this.body.payment = {
						type: 'voluntary_payment',
						data: {
							transaction_type: null,
							transacion_number: null,
							card_number: null,
							transacion_note: null,
							image: ''
						}
					};
				}
			}
			console.log(this.selectedCard);
		});
	}

	selectCard(value) {
		if (value) {
			this.selectedCreditCard = value;
			this.cardPlan = value;
			this.body.payment.pay_now = true;
			this.payNow.checked = true;
			this.changePaymentType('card_token');
		} else {
			this.body.payment.pay_now = false;
			this.payNow.checked = false;
			this.changePaymentType('credit_card');
		}
	}

	getPlans(value) {
		this.plansByType = [];
		this.body.plan_id = null;
		this.body.price = null;
		for (let i = 0; i < this.plans.length; i++) {
			if (this.plans[i].plan_type_id == value) {
				this.plansByType.push(this.plans[i]);
			}
		}
	}

	changePlan(value) {
		for (let i = 0; i < this.plansByType.length; i++) {
			if (this.plansByType[i].id == value) {
				this.body.price = this.plansByType[i].price;
				this.selectedPlan = this.plansByType[i];
			}
		}
	}

	changePaymentType(type) {
		this.paymentType = type;
		//console.log(type,this.paymentType);
		if (type == 'credit_card') {
			this.body.payment = {
				type: 'credit_card',
				creditCard: {
					name: null,
					cvv: null,
					card_number: null,
					date_of_issue: null
				}
			};
		} else if (type == 'vol') {
			this.body.payment = {
				type: 'voluntary_payment',
				data: {
					transaction_type: null,
					transacion_number: null,
					card_number: null,
					transacion_note: null,
					image: ''
				}
			};
		} else if (this.paymentType == 'card_token') {
			this.body.payment = {
				type: 'card_token',
				card_id: this.selectedCreditCard,
				pay_now: true,
			};
		} else {
			this.body.payment = null;
		}
	}

	async onFileChange(event) {
		var $event = event;

		if (this.photo_data[0]) {
			const fileReader = await this.fileRender(this.photo_data[0]);
			//console.log(fileReader, this.body.payment);
			this.body.payment.data.image = fileReader;
		}
	}

	fileRender(inputFile): any {
		const temporaryFileReader = new FileReader();
		return new Promise((resolve, reject) => {
			temporaryFileReader.onerror = () => {
				temporaryFileReader.abort();
				reject(new DOMException('Problem parsing input file.'));
			};

			temporaryFileReader.onload = () => {
				resolve(temporaryFileReader.result);
			};
			temporaryFileReader.readAsDataURL(inputFile);
		});
	}

	reset(): void {
		this.isEditingCard = false;
		this.selectedCard = 0;
		this.photo_data = null;
		this.paymentType = 'credit_card';
		this.body = {
			id: null,
			price: 0,
			plan_id: null,
			frequency: 'monthly',
			type: 'new',
			user_id: null,
			payment: {
				type: 'credit_card',
				creditCard: {
					name: null,
					cvv: null,
					card_number: null,
					date_of_issue: null
				}
			}
		};
		this.typeSelected = null;
		this.plansByType = [];
		this.httpServices.getHttpAuth('/plan', {}).subscribe(data => {
			this.plans = data.plans;
			this.planTypes = data.types;
			this.policyType = null;
		});
	}

	save() {
		this.isLoading.emit({
			isLoading: true,
			isFinish: false
		});
		console.log(this.body);
		this.httpServices
			.postHttpAuth('/order/payment/user', this.body)
			.subscribe(
				data => {
					this.reset();
					this.isLoading.emit({
						isLoading: false,
						isFinish: true
					});

					this.toastr.success(
						this.body.type == 'new'
							? 'Se ha agregado el plan de manera correcta'
							: '',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => {
					this.isLoading.emit({
						isLoading: false,
						isFinish: false
					});
				}
			);
	}

	// plansByType

	edit_card() {
		this.isLoading.emit({
			isLoading: true,
			isFinish: false
		});

		this.httpServices
			.putHttpAuth('/subscription/data', {
				id: this.body.id,
				card_id: this.selectedCard,
				amount: this.body.price
			})
			.subscribe(
				data => {
					this.isLoading.emit({
						isLoading: false,
						isFinish: true
					});

					this.toastr.success(
						'Plan modificado correctamente',
						'Proceso exitoso',
						{
							closeButton: false
						}
					);
				},
				err => {
					this.isLoading.emit({
						isLoading: false,
						isFinish: false
					});
				}
			);
	}
}
