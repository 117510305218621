import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component';
import {LoginComponent} from './pages/login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ContentComponent} from './pages/content/content.component';
import {AppointmentsComponent} from './request/appointments/appointments.component'
import {MedicationComponent} from './request/medication/medication.component'
import {InvoicesComponent} from './request/invoices/invoices.component';
import {UsersComponent} from './pages/users/users.component';
import {CreateComponent} from './pages/users/create/create.component';
import {TransactionsComponent} from './pages/transactions/transactions.component';
import {AditionalComponent} from './request/aditionalHelp/aditional.component';
import {DataConfigComponent} from './pages/config/data-config/data-config.component';
import {PlansComponent} from './pages/config/plans/plans.component';
import {TermsComponent} from './pages/terms/terms.component';
import {UpdateAdminComponent} from './pages/users/update-admin/update-admin.component';
import {PlansListComponent} from './pages/plans/plans.component';
import { ProductsComponent } from './pages/products/products.component';
import { IssuanceDocumentsComponent } from './pages/issuance-documents/issuance-documents.component';
import { DocumentComponent } from './pages/issuance-documents/document/document.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        component: ContentComponent,
        pathMatch: 'full',
    },
    {
        path: 'appoinments',
        component: AppointmentsComponent
    },
    {
        path: 'medication',
        component: MedicationComponent
    },
    {
        path: 'users',
        component: UsersComponent
    },
    {
        path: 'users/list/:type',
        component: UsersComponent
    },
    {
        path: 'users/new',
        component: CreateComponent
    },
    {
        path: 'users/edit/:id',
        component: CreateComponent
    },
    {
        path: 'users-admin/edit/:id',
        component: UpdateAdminComponent
    },
    {
        path: 'config/:type',
        component: DataConfigComponent
    },
    {
        path: 'plans',
        component: PlansComponent
    },
    {
        path: 'transactions',
        component: TransactionsComponent
    },
    {
        path: 'plans-history',
        component: PlansListComponent
    },
    {
        path: 'invoices',
        component: InvoicesComponent
    },
    {
        path: 'aditionals',
        component: AditionalComponent
    },
    {
        path: 'terms',
        component: TermsComponent
    },
    {
        path: 'products',
        component: ProductsComponent
    },
    {
        path: 'issuance-documents',
        component: IssuanceDocumentsComponent
    },
    {
        path: 'issuance-documents/document',
        component: DocumentComponent
    }
    

];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {useHash: true})
    ],
    exports: [],
})
export class AppRoutingModule {
}
