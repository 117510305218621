import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "./../../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute} from "@angular/router";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-data-config',
  templateUrl: './data-config.component.html',
  styleUrls: ['./data-config.component.scss']
})
export class DataConfigComponent implements OnInit {

  constructor(private router:Router,private _route: ActivatedRoute, private httpServices: HttpService, public toastr: ToastrService) { }
  
  type: string = "";
  data: any = [];
  nameToEdit: any = "";
  selected: any = {};
  sub: any=null;
  range1: number = 0;
  isEditing: boolean = false;
  public loading: boolean=false;
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Listado de planes', 
        exportOptions:{
          stripNewlines: false,
          format: {
              body: function(data, row, column, node) {              
                     if(column==5)
                       return data.replace('$', '');
                     else if(column==8)
                       return data.replace(/<\/?[^>]+(>|$)/g, "");
                     else 
                      return data;
              }
          },
          columns: ':not(.notexport)'
        }
      }
    ]
  };
  dtTrigger: Subject<any> = new Subject();

  ngOnInit() {
    var $this = this;
    this.sub = this._route.params.subscribe(params => {
       const term = params['type'];
       $this.changeParameters(term);
     });  
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  changeParameters(type):void {    
    if(type=='specialities'){
      this.type = "especialiades medicas";
      
      this.httpServices.getHttpAuth("/specialities")
      .subscribe(x => {
        this.data =  x.specilities;
        this.rerender();
      },x=>this.loading=false);
    }
    else if(type=='insurance'){
      this.type = "Aseguradoras";
    
      this.httpServices.getHttpAuth("/insurance")
      .subscribe(x => {
        this.data =  x.insurance;
        this.rerender();
      },x=>this.loading=false);
    }
    else if(type=='countries'){
      this.type = "Paises";
      
      this.httpServices.getHttpAuth("/countries")
      .subscribe(x => {
        this.data =  x.countries;
        this.rerender();
      },x=>this.loading=false);
    }
    else if(type=='units'){
        this.type = "Unidades de medida";
        
        this.httpServices.getHttpAuth("/find/units")
        .subscribe(x => {
          this.data =  x.units;
          this.rerender();
        },x=>this.loading=false);
    }
    else if(type=='medicine'){
        this.type = "Medicamentos";
        
        this.httpServices.getHttpAuth("/find/medicine")
        .subscribe(x => {
          this.data =  x.medicines;
          this.rerender();
        },x=>this.loading=false);
    }
    else if(type=='quantities'){
        this.type = "cantidades";
        
        this.httpServices.getHttpAuth("/find/quantities")
        .subscribe(x => {
          this.data =  x.quantities;
          this.rerender();
        },x=>this.loading=false);
    }
    else if(type=='range'){
        this.type = "rangos";
        this.httpServices.getHttpAuth("/find/ranges")
        .subscribe(x => {
          this.data =  x.ranges;
          this.rerender();
        },x=>this.loading=false);
    }
    else if(type=='presentation'){
        this.type = "presentaciones";
        this.httpServices.getHttpAuth("/presentations/drugs/get")
        .subscribe(x => {
          this.data =  x.presentations;
          this.rerender();
        },x=>this.loading=false);
    }
  }
  
  openEdit(i,type){
    
    if(type=='edit'){
      this.selected = i;
      if(this.type=='rangos'){
          this.range1=this.data[i].first_value; 
      }
      else if(this.type=='cantidades')
        this.nameToEdit=this.data[i].quantity;
      else
        this.nameToEdit=this.data[i].name;
        
      this.isEditing=true;
    }
    else{
      this.nameToEdit="";
      this.range1=0;
      this.isEditing=false;
    }
    (<any>$('#edit')).modal('show');
  }
  
  update(){
    let type = this._route.snapshot.params.type;
    
    if(type=='insurance')
      var url = "/insurance/"+this.data[this.selected].id;
    else if(type=='countries')
      var url = "/countries/"+this.data[this.selected].id;  
    else if(type=='specialities')
      var url = "/specialities/"+this.data[this.selected].id;  
    else if(type=='units')
      var url = "/units/"+this.data[this.selected].id;
    else if(type=='medicine')
      var url = "/medicinecrud/"+this.data[this.selected].id;
    else if(type=='quantities')
      var url = "/quantities/"+this.data[this.selected].id;  
    else if(type=='range')
        var url = "/ranges/"+this.data[this.selected].id;
    else if(type=='presentation')
            var url = "/presentations/drugs/put/"+this.data[this.selected].id;
            
    var data = <any> {name:this.nameToEdit};
    
    if(type=='range')
     data = {first_value:this.range1};
    if(type=='quantities')
     data = {quantity:this.nameToEdit};    
               
    this.loading = true;
    this.httpServices.putHttpAuth(url,data)
    .subscribe(x => {
      this.loading = false;  
      if(type!='range' && type!='quantities')
        this.data[this.selected].name=this.nameToEdit;
      
      if(type=='quantities')
        this.data[this.selected].quantity=this.nameToEdit;
        
      if(type=='range'){
          this.data[this.selected].first_value=this.range1;
      }
        
      (<any>$('#edit')).modal('hide');

      this.rerender();
      this.toastr.success("Recurso editado correctamente",'Proceso exitoso',{
        closeButton: false  
      });
    },x=>this.loading=false);
    
    
  }
  
  store() {
    var type = this._route.snapshot.params.type;
    if(type=='insurance')
      var url = "/insurance";
    else if(type=='countries')
      var url = "/countries";  
    else if(type=='specialities')
      var url = "/specialities";  
    else if(type=='units')
        var url = "/units";  
    else if(type=='medicine')
        var url = "/medicinecrud";
    else if(type=='quantities')
         var url = "/quantities";    
    else if(type=='range')
        var url = "/ranges";
    else if(type=='presentation')
            var url = "/presentations/drugs/post";
        
    var data = <any> {name:this.nameToEdit};
        
    if(type=='range')
        data = {first_value:this.range1};
        
    if(type=='quantities')
        data = {quantity:this.nameToEdit};       
         
    this.loading = true;
    this.httpServices.postHttpAuth(url,data)
    .subscribe(x => {
      this.loading = false;  
      (<any>$('#edit')).modal('hide');
      this.data.push(x.data);
      this.rerender();
      this.toastr.success("Recurso agregador correctamente",'Proceso exitoso',{
        closeButton: false  
      });
    },x=>this.loading=false);
  }
  
  delete(i) {
    let type = this._route.snapshot.params.type;
    console.log(type);
    if(type=='specialities')
      var url = "/specialities/"+this.data[i].id;
    if(type=='insurance')
      var url = "/insurance/"+this.data[i].id;
    if(type=='countries')
      var url = "/countries/"+this.data[i].id;
    if(type=='units')
      var url = "/units/"+this.data[i].id;
    else if(type=='medicine')
      var url = "/medicinecrud/"+this.data[i].id; 
    else if(type=='quantities')
       var url = "/quantities/"+this.data[i].id;      
    else if(type=='range')
        var url = "/ranges/"+this.data[i].id; 
    else if(type=='presentation')
        var url = "/presentations/drugs/delete/"+this.data[i].id;    
      
    this.loading = true;      
    this.httpServices.deleteHttpAuth(url,{})
    .subscribe(x => {
      this.loading = false;  
      this.data.splice(i,1);
      this.rerender();
      this.toastr.success("Recurso eliminado correctamente",'Proceso exitoso',{
        closeButton: false  
      });
    },x=>this.loading=false);
  }

}
