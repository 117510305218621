import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtertype'
})
export class FilterTypeProduct implements PipeTransform {

  transform(type: any): any {

    if(type == 1)
      return 'Producto'

    if(type == 2)
      return 'Servicio'

  }

}
