import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    canSee: Array<number>;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Inicio',  icon:'home', class: '', canSee:[2,3,4,5] },
  { path: '/users', title: 'Usuarios',  icon:'person', class: '', canSee:[2,3,4,5] },
  { path: '/transactions', title: 'Transacciones',  icon:'attach_money', class: '', canSee:[2,4] },
  { path: '/plans-history', title: 'Planes',  icon:'attach_money', class: '', canSee:[2,4] },
  { path: '/appoinments', title: 'Citas médicas',  icon:'local_hospital', class: '', canSee:[2,3,5] },
  { path: '/medication', title: 'Medicinas',  icon:'add_shopping_cart', class: '', canSee:[2,3,5] },
  { path: '/products', title: 'Productos',  icon:'add_shopping_cart', class: '', canSee:[2,4] },
  { path: '/invoices', title: 'Facturas',  icon:'file_copy', class: '', canSee:[2,3,5] },
  { path: '/issuance-documents', title: 'Emisión de Documentos',  icon:'file_copy', class: '', canSee:[2,3,5] },
  { path: '/aditionals', title: 'Solicitudes adicionales',  icon:'add', class: '', canSee:[2,5,3] }
];

export const sidebarItems: RouteInfo[] = [
  { path: '/dashboard', title: 'Inicio',  icon:'home', class: '', canSee:[2,3,4,5] },
  { path: '/users', title: 'Usuarios',  icon:'person', class: '', canSee:[2,3,4,5] },
  { path: '/transactions', title: 'Transacciones',  icon:'attach_money', class: '', canSee:[2,4] },
  { path: '/plans-history', title: 'Planes',  icon:'attach_money', class: '', canSee:[2,4] },
  { path: '/products', title: 'Productos',  icon:'add_shopping_cart', class: '', canSee:[2,4] },
  { path: '/issuance-documents', title: 'Emisión de Documentos',  icon:'file_copy', class: '', canSee:[2,3,5] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  role: number = 2;
  constructor() { }

  ngOnInit() {
    this.menuItems = sidebarItems.filter(menuItem => menuItem);
    this.role = parseInt(localStorage.getItem('role'));
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
