import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpService } from "../../services/Http/http.service"
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { copyStyles } from '@angular/animations/browser/src/util';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  products = [];
  titleModal = '';
  types = [
    { value: 1, name: 'Producto'},
    { value: 2, name: 'Servicio'}
  ]
  itbms = [ 0, 7, 10, 15];
  form: FormGroup;
  submitted = false;
  isEdit = true;
  idEdit = '';
  dtOptions: any = {
    pagingType: 'full_numbers',
    "language": {
      "url": "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json"
    },
    "pageLength": 10,
    dom: 'Bfrtip',
    buttons: [
        { extend: 'excel', title: 'Reporte de Productos', 
        exportOptions:{
          stripNewlines: false,
          format: {
              body: function(data, row, column, node) {              
                     if(column==1)
                       return data.replace(/<\/?[^>]+(>|$)/g, "");
                     else 
                      return data;
              }
          },
          columns: ':not(.notexport)'
        }
      }
    ]
  };
  dtTrigger: Subject<any> = new Subject();
  dataDelete={id:'', code:''};
  loading: boolean = false;
  loadingDelete: boolean = false;

  constructor(
    private httpServices: HttpService, 
    public toastr: ToastrService, 
    private fb: FormBuilder) { 

      this.createForm();

    }

  get f(): any { return this.form.controls; }
 
  ngOnInit() {
    this.loadProducts();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  private createForm(): void {
      this.form = this.fb.group(
        {
          description: [ '', Validators.required ],
          code: [ '', [ Validators.required, Validators.maxLength(10)]  ],
          type: [ '',  Validators.required ],
          itbms: [ '',  Validators.required  ],
          price: [ '',  Validators.required  ]
        }
      );
  }


  addModal() {
    this.titleModal = 'Agregar Productos/Servicios';
    this.isEdit = false;
    this.createForm();
    (<any>$('#modalDetails')).modal('show');
  }

  editModal(product) {
    this.titleModal = 'Editar Productos/Servicios';  
    this.idEdit = product.id;
    this.isEdit = true;
    this.form = this.fb.group(
      {
        description: [ product.description, Validators.required ],
        code: [ product.code, [ Validators.required, Validators.maxLength(10)]  ],
        type: [ product.type,  Validators.required ],
        itbms: [ product.itbms,  Validators.required  ],
        price: [ product.price,   Validators.required  ]
      }
    );
    (<any>$('#modalDetails')).modal('show');
  }

  loadProducts() {
    this.httpServices.getHttpAuth("/products?paginate=1")
    .subscribe( x => {
      //console.log(x.data);
      this.products = x.data;
      this.rerender();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if ( this.form.valid ) {
      ( this.isEdit ) ? this.editProduct() : this.addProduct();
    }
  }

  openDelete(id, code){
    this.dataDelete={id:id,code:code};
    (<any>$('#modalEliminar')).modal('show');
  }

  deleteProduct() {
    this.loadingDelete = true;
    this.httpServices.deleteHttpAuth('/products/' + this.dataDelete.id, {}).subscribe(
			x => {
        (<any>$('#modalEliminar')).modal('hide');
				this.toastr.success('Producto/Servicio eliminado con exito');
        this.loadProducts();
        this.loadingDelete = false;
		}, err => {
      this.loadingDelete = false;
    }
    ); 
  }

  editProduct() {
    this.submitted = true;
    if ( this.form.valid ) {
      this.loading = true;
      this.httpServices.putHttpAuth("/products/"+this.idEdit, this.form.value)
      .subscribe( x => {
        this.submitted = false;
        this.loading = false;
        (<any>$('#modalDetails')).modal('hide');
        this.toastr.success('Producto/Servicio editado con exito');
        this.loadProducts();
      }, err => {
        this.loading = false;
      }
      );
    }
  }

  addProduct() {
    this.submitted = true;
    //console.log(this.form.value);
    if ( this.form.valid ) {
      this.loading = true;
      this.httpServices.postHttpAuth('/products', this.form.value)
      .subscribe( x => {
        //console.log(x);
        this.submitted = false;
        this.loading = false;
         (<any>$('#modalDetails')).modal('hide');
        this.toastr.success('Producto/Servicio agregado con exito');
        this.loadProducts();
      }, err => {
        this.loading = false;
      }
      );
    }
  }

}
