import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpService } from "./services/Http/http.service";
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {NgxMaskModule} from 'ngx-mask'
import { DataTablesModule } from 'angular-datatables';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AppComponent } from './app.component';
import { NgxLoadingModule } from 'ngx-loading';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { AppointmentsComponent } from './request/appointments/appointments.component';
import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/login/login.component';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule,
  MatTabsModule,
  MatStepperModule,
  MatNativeDateModule,
  MatProgressSpinnerModule
  
} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { ContentComponent } from './pages/content/content.component';
import { MedicationComponent } from './request/medication/medication.component';
import { InvoicesComponent } from './request/invoices/invoices.component';
import { AditionalComponent } from './request/aditionalHelp/aditional.component';
import { UsersComponent } from './pages/users/users.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { PlansListComponent } from './pages/plans/plans.component';
import { CreateComponent } from './pages/users/create/create.component';
import { DataConfigComponent } from './pages/config/data-config/data-config.component';
import { TermsComponent } from './pages/terms/terms.component';
// import { NgxEditorModule } from 'ngx-editor';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { UpdateAdminComponent } from './pages/users/update-admin/update-admin.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { PlansComponent } from './pages/config/plans/plans.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ProductsComponent } from './pages/products/products.component';
import { FilterTypeProduct } from './pages/products/filter-type.pipe';
import { IssuanceDocumentsComponent } from './pages/issuance-documents/issuance-documents.component';
import { DocumentComponent } from './pages/issuance-documents/document/document.component';
import { DatePipe } from '@angular/common';
import { FilterTotalIssuance } from './pages/issuance-documents/filter-total.pipe';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        ComponentsModule,
        RouterModule,
        AppRoutingModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        }),
        ToastrModule.forRoot(),
        MatButtonModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTabsModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatStepperModule,
        NgxMaskModule.forRoot(),
        DataTablesModule,
        ReactiveFormsModule,
        FileUploadModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxLoadingModule.forRoot({}),
        FroalaEditorModule.forRoot(),
        FroalaViewModule.forRoot(),
        MatSlideToggleModule,
        // NgxEditorModule
    ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    UpgradeComponent,
    LoginComponent,
    DashboardComponent,
    UserProfileComponent,
    TableListComponent,
    TypographyComponent,
    IconsComponent,
    MapsComponent,
    NotificationsComponent,
    ContentComponent,
    AppointmentsComponent,
    MedicationComponent,
    UsersComponent,
    InvoicesComponent,
    AditionalComponent,
    TransactionsComponent,
    CreateComponent,
    DataConfigComponent,
    TermsComponent,
    UpdateAdminComponent,
    SubscriptionComponent,
    PlansComponent,
    PlansListComponent,
    ProductsComponent, 
    FilterTypeProduct,
    IssuanceDocumentsComponent,
    DocumentComponent,
    FilterTotalIssuance,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
