import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from "rxjs";
import { catchError, retry } from 'rxjs/operators';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute} from "@angular/router";
@Injectable({
  providedIn: 'root'
})




export class HttpService {

  constructor(private router:Router, private httpClient: HttpClient, private toastr: ToastrService) { }
  //
  base_api: string = environment.url_api;
  options: any;



  headers: any = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}


  getHttp(url_parameter: string): Observable<any>{

    return this.httpClient.get(this.base_api+url_parameter)
    .pipe(
        catchError(this.handleError.bind(this))
    );

  }

  getHttpAuth(url_parameter: string, options?: any): Observable<any>{

    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.get(this.base_api+url_parameter,headers)
    .pipe(
        catchError(this.handleError.bind(this))
    );

  }

  postHttpAuth(url_parameter: string, parameters: any, options?:any): Observable<any>{

    if(options)
      this.options=options;

    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.post(this.base_api+url_parameter,parameters,headers)
    .pipe(
        catchError(
        this.handleError.bind(this)
      )
    );
  }

  postHttp(url_parameter: string, parameters: any): Observable<any>{

    return this.httpClient.post(this.base_api+url_parameter,parameters)
    .pipe(
        catchError(
      this.handleError.bind(this)
      )
    );
  }


  deleteHttpAuth(url_parameter: string, parameters: any): Observable<any>{
    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.delete(this.base_api+url_parameter,headers)
    .pipe(
        catchError(
        this.handleError.bind(this)
      )
    );
  }

  putHttpAuth(url_parameter: string, parameters: any): Observable<any>{
    var headers = {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}};
    return this.httpClient.put(this.base_api+url_parameter,parameters,headers)
    .pipe(
       // retry a failed request up to 3 times
      catchError(
        this.handleError.bind(this)
      )
    );
  }


   handleError(error: HttpErrorResponse) {

      if(this.options){
        this.options.disabled=false;
        this.options.isLoading=false;
      }

      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        this.toastr.error("",'Error inesperado');
        return throwError(
        'Something bad happened; please try again later.');
      } else {

    
        if(error.status==422){
           /* var strHtml = "<ul>";
            for(let i=0; i < error.error.errors.length; i++ ){
              let msg = error.error.errors[i];
              strHtml+="<li>"+msg+"</li>";
            }
            strHtml+="</ul>";
            */
            const msg = error.error.message;
            const errors = error.error.errors;

          
            if(errors!==undefined) {
              let strHtml = "<ul>";
              for ( const key in errors ) {
                if ( Object.prototype.hasOwnProperty.call( errors, key ) ) {
                  const element = errors[ key ];
                  strHtml+="<li>"+element+"</li>";
                }
              }
              strHtml+="</ul>";

              this.toastr.error(strHtml,'Existen errores en tu formulario', {
                closeButton: false,
                enableHtml: true
              });
            } else {
              if(msg!=='') {
                this.toastr.error(msg);
              }else {
                this.toastr.error("",'Hubo un error inesperado');
              }
            }
            return throwError(
            'Something bad happened; please try again later.');

        }

        if(error.status==401){
            this.router.navigate(['/login'])
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.toastr.error("Vuelva a iniciar sesión",'Sesión expirada');
            return throwError(
            'Something bad happened; please try again later.');
        }

        if(error.status==404){
            this.toastr.error("",'Recurso no encontrado');
            return throwError(
            'Something bad happened; please try again later.');
        }

        if(error.status==400){
          this.toastr.error("No se pudo procesar su transacción",'Error');
          // if(error.error.code){
          //   this.toastr.error("",(messages[error.error.code] ? messages[error.error.code] : msg));
          // }

          return throwError(
          'Something bad happened; please try again later.');
        }

        if(error.status==500){
          
          const msg = error.error.message;
          const errors = error.error.errors;
          
          if(errors!==undefined) {

            let strHtml = "<ul>";
            for ( const key in errors ) {
              if ( Object.prototype.hasOwnProperty.call( errors, key ) ) {
                const element = errors[ key ];
                strHtml+="<li>"+element+"</li>";
              }
            }
            strHtml+="</ul>";

            this.toastr.error(strHtml,'Existen errores en tu formulario', {
              closeButton: false,
              enableHtml: true
            });
          } else {
            if(msg!=='') {
              this.toastr.error(msg);
            }else {
              this.toastr.error("",'Hubo un error inesperado');
            }
          }
          return throwError(
          'Something bad happened; please try again later.');

          /*this.toastr.error("",'Hubo un error inesperado');
          return throwError(
          'Something bad happened; please try again later.');
          */

        }
      }
    // return an observable with a user-facing error message
      return throwError(
      'Something bad happened; please try again later.');
  };


}
